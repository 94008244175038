<script setup>
import { onMounted } from "vue"
import { ref } from "@vue/reactivity"
import Menu from "@/components/Menu"
import router from "./router"
import { KNOLIA } from "./knolia";

const reload = ref(false)
const reloadMenu = ref(false)

// function Reload() {
//   router.currentRoute.value.params.id = null
//   router.currentRoute.value.params.userId = null
//   reload.value = !reload.value
// }

function IsSubMenuPage() {
  return router.currentRoute.value.meta.subMenu ? 'div-content-submenu' : 'div-content'
}

onMounted(() => {
  KNOLIA.CheckCredentials(router.currentRoute.value.meta.requiresAuth);
})

</script>

<template>
  <div class="app-container">
    <div class="div-master" id="div-master" v-if="router.currentRoute.value.path != '/privacy'">
      <Menu :key="reloadMenu"/>
      <div :class="IsSubMenuPage()">
        <router-view :key="reload" @force-reload="reloadMenu = !reloadMenu" />
      </div>
    </div>

    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<style scoped>
.app-container {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(135deg, #4864fa 40%, #29fefe);
}

.div-master {
  min-height: 100vh;
  display: flex;
  height: fit-content;
}

.div-content,
.div-content-submenu {
  z-index: 3;
  width: 83%;
  overflow: hidden;
  min-height: -webkit-fill-available;
}

.div-content-submenu {
  background: black;
}

</style>
