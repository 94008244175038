class MESSAGES {

    static INFO = {
        BROWSER_WARNING: 'Certifique-se de que o seu browser não está a bloquear o download',
    }

    static SUCCESS = {
        SAVED: 'Salvo com sucesso',
        CHANGED: 'Alterado com sucesso',
        REMOVED: 'Removido com sucesso',
        INSERTED: 'Inserido com sucesso',
        LOGIN_CHANGED: 'Nova sessão detetada'
    }

    static ERROR = {
        DEFAULT: 'Ocorreu um erro',
        PRIVACY: 'Obrigatório dar ciência da declaração de privacidade',
        NOT_ALLOWED: 'Não permitido',
        TYPE_UNCHOSEN: 'Escolha o tipo',
        LIST_UNNAMED: 'É preciso dar nome a lista antes de guardá-la',
        USER_INACTIVE: 'Utilizador inativo',
        LOGIN_INVALID: 'Username ou password inválidos',
        NEW_PASS_EMPTY: 'É necessário informar a nova password',
        NEW_PASS_UNCONFIRMED: 'É necessário confimar a password',
        RATE_ABILITY: 'É necessário classificar a competência'
    }

    static QUESTIONS = {
        REMOVE_FROM_TREE: 'Deseja eliminar este elemento e os seus filhos?',
        REMOVE_COLUMN_TREE: 'Apagar a coluna?'
    }
}

export { MESSAGES }