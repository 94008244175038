<script setup>
import { defineEmits, defineProps, onMounted, ref } from 'vue'
import { Gems } from '@/gems'

const emits = defineEmits(['select-file', 'import'])
const props = defineProps(['tableName'])
const label = ref(null)
const placeholder = ref(null)
const btnImport = ref()

function OpenFileSelect() {
    document.getElementById(props.tableName).click()
}

function SetPlaceholder() {
  placeholder.value = document.getElementById(props.tableName).files[0].name ?? null
  btnImport.value.classList.remove('blink-import')
  btnImport.value.classList.add('blink-import')
}

function Import() {
  btnImport.value.classList.remove('blink-import')
  emits('import', props.tableName)
  placeholder.value = null
}

onMounted(() => {
  if(label.value)
    Gems.Tooltip(label.value, "Selecionar ficheiro [ CSV UTF-8 (Comma delimited) (*.csv) ]", "tooltip")
})

</script>

<template>
    <div class="div-inputs">
        <input ref="label" class="inputfile cursor-pointer" type="text" @click="OpenFileSelect()"
        :placeholder="placeholder === null ? 'Selecionar ficheiro [ CSV UTF-8 (Comma delimited) (*.csv) ]' : placeholder" readonly />
        <input class="hidden-input" :id="props.tableName" type="file" @change="SetPlaceholder()"/>
    </div>
    <button ref="btnImport" class="btn-import" @click="Import">IMPORTAR <i class="fas fa-arrow-up"></i></button>
</template>

<style scoped>
.inputfile {
  width: -webkit-fill-available;
  text-align: center;
}

.inputfile::placeholder {
  color: var(--default-app-color);
}

.hidden-input {
  display: none;
}

.div-inputs {
    width: 80%;
}

.btn-import {
  width: 20%;
  margin-left: 5px;
}
</style>