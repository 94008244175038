<script setup>
import { ref } from "vue"
import { KNOLIA } from "@/knolia"
import { defineEmits, defineProps } from "vue"
import CommentBalloon from "./CommentBalloon.vue"

const props = defineProps(["comments", "element"])
const emits = defineEmits(["get-content"])

let textAreaMessage = ref('');
let commentReply = ref([])

function ControlReply(comment = []) {
  commentReply.value = comment
}

async function SendMessage() {
  if(textAreaMessage.value.value == '')
    return 
  
  await KNOLIA.CallBackEnd("post", "set-operations&get=send-comment", {
    comment: textAreaMessage.value.value,
    replied_to: commentReply.value.comment_id,
    element: props.element
  }).then((res) => {
    textAreaMessage.value.value = ''
    commentReply.value = []
    if(res.data.abilities_ratings_id)
      Object.assign(props.element, {abilities_ratings_id: res.data.abilities_ratings_id})

      Object.assign(props.element, {has_comments: true})
    emits('get-content')
  });
}

async function DeleteComment(commentId) {
  await KNOLIA.CallBackEnd("post", "set-operations&get=delete-comment", {
    commentId
  }).then(() => {
    emits('get-content')
  });
}

function GoToComment(id) {
  const comment = document.getElementById(`comment${id}`)
  comment.scrollIntoView()
  comment.classList.add('replied-message')
}

</script>

<template>
  <div class="div-comment-area">
    <div class="div-comment-container scrollbar">
      <CommentBalloon :comment="comment" :reply="false" v-for="comment in props.comments" :key="comment.comment_id" @control-reply="ControlReply($event)" @delete-comment="DeleteComment($event)">
        <CommentBalloon :comment="comment.replied_comment" :replied_comment="true" v-if="Object.keys(comment.replied_comment).length > 0" @click="GoToComment(comment.replied_to)" />
      </CommentBalloon>
      <CommentBalloon :comment="comment" :reply="false" v-for="comment in props.comments" :key="comment.comment_id" @control-reply="ControlReply($event)" @delete-comment="DeleteComment($event)">
        <CommentBalloon :comment="comment.replied_comment" :replied_comment="true" v-if="Object.keys(comment.replied_comment).length > 0" @click="GoToComment(comment.replied_to)" />
      </CommentBalloon>
      <CommentBalloon :comment="comment" :reply="false" v-for="comment in props.comments" :key="comment.comment_id" @control-reply="ControlReply($event)" @delete-comment="DeleteComment($event)">
        <CommentBalloon :comment="comment.replied_comment" :replied_comment="true" v-if="Object.keys(comment.replied_comment).length > 0" @click="GoToComment(comment.replied_to)" />
      </CommentBalloon>
      <CommentBalloon :comment="comment" :reply="false" v-for="comment in props.comments" :key="comment.comment_id" @control-reply="ControlReply($event)" @delete-comment="DeleteComment($event)">
        <CommentBalloon :comment="comment.replied_comment" :replied_comment="true" v-if="Object.keys(comment.replied_comment).length > 0" @click="GoToComment(comment.replied_to)" />
      </CommentBalloon>
      <CommentBalloon :comment="comment" :reply="false" v-for="comment in props.comments" :key="comment.comment_id" @control-reply="ControlReply($event)" @delete-comment="DeleteComment($event)">
        <CommentBalloon :comment="comment.replied_comment" :replied_comment="true" v-if="Object.keys(comment.replied_comment).length > 0" @click="GoToComment(comment.replied_to)" />
      </CommentBalloon>
    </div>
    <div class="div-send-message-space">
      <div class="reply-comment" v-if="Object.keys(commentReply).length > 0">
        <CommentBalloon :comment="commentReply" :reply="true" @control-reply="ControlReply($event)"/>
      </div>
      <div class="div-send-message">
        <input type="text" ref="textAreaMessage" class="textarea-comment" />
        <button @click="SendMessage()" class="button-send-comment">Enviar</button>
      </div>
    </div>
  </div>
</template>

<style scoped>

.div-comment-container {
  padding: 10px;
  background-color: transparent;
  height: 410px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.div-comment-container > section:nth-child(n + 2) {
  margin-top: 5px;
}

.div-send-message-space {
  padding: 9px 10px;
  background-color: transparent;
}

.div-send-message {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.textarea-comment {
  flex: 5;
}

.reply-comment {
  padding-left: 15px;
}

.reply-comment section{
  margin-bottom: 10px;
}

.button-send-comment {
  flex: 1;
  line-height: 4px;
}
</style>
