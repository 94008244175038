<script setup>
import { useRouter } from "vue-router"
// import { KNOLIA } from "../knolia";

let router = useRouter()

// function ToSkillCard() {
//     KNOLIA.SetRequestedUserId(KNOLIA.GetLoggedUserId)
//     router.push('/skill-card')
// }
</script>

<template>
  <div class="div-container">
    <div class="buttons">
        <!-- <button class="btn-white" @click="ToSkillCard()">CÉDULA DE COMPETÊNCIAS</button> -->
        <button class="btn-white" @click="router.push('rank-skills')">CLASSIFICAR COMPETÊNCIAS</button>
    </div>
  </div>

</template>

<style scoped>

.div-container {
  margin: 10% auto;
  flex-direction: column;
  display: flex;
  width: 900px;
}

.buttons button {
  width: 300px;
  height: 50px;
  margin: 10px;
}

.buttons {
  align-self: center;
}
</style>
