<script setup>
import { defineEmits, onUpdated, onMounted, ref } from "vue";
import { KNOLIA } from "../knolia";
import { Gems } from "@/gems";
import { useStore } from "vuex";
import DepthTasksElement from "../components/DepthTasksElement";
import TreeType from "../enums/TreeType";
import { MESSAGES } from "../messages";

// eslint-disable-next-line no-undef
let props = defineProps(["column", "meta_id", "is_meta"]);
let emit = defineEmits(["filter-children"]);
const store = useStore();
let showOptions = ref(false);
let txtDepthName = ref();

// async function Drop(event, element) {
//   let checkDepth = element.totalDepth > 3 ? element.totalDepth - 2 : 1;
//   let newItem = JSON.parse(event.dataTransfer.getData("element"));
//   newItem["showSelf"] = element.selected;

//   if (newItem.fromSkillsList) {
//     newItem["depth"] = parseInt(element.depth, 10);
//     if (element.depth == checkDepth) {
//       await KNOLIA.CallBackEnd("post", "update-tree-position", {
//         element: newItem,
//         target: element,
//         to: "depth",
//       }).then(() => {
//         store.dispatch("UpdateTasksTree");
//       });
//     }
//   } else if (newItem.fromElementList) {
//     newItem["depth"] = parseInt(element.depth, 10);

//     if (element.depth == checkDepth) {
//       await KNOLIA.CallBackEnd("post", "update-tree-position", {
//         element: newItem,
//         target: element,
//         to: "depth",
//       }).then(() => {
//         store.dispatch("UpdateTasksTree");
//       });
//     }
//   } else {
//     if (newItem.depth >= parseInt(element.depth, 10)) {
//       await KNOLIA.CallBackEnd("post", "update-tree-position", {
//         element: newItem,
//         target: element,
//         to: "depth",
//       }).then(() => {
//         store.dispatch("UpdateTasksTree");
//       });
//     } else {
//       Gems.Telegram(
//         "Não é possível arrastar um elemento para um nível superior ao seu nível atual, ou a si próprio",
//         "tele-not-allowed"
//       );
//       return;
//     }
//   }
// }

function ChangeDepthName(column) {
  store.dispatch("UpdateDepthName", {
    name: txtDepthName.value.value,
    element: column,
    update: "name",
    treeType: "tasks",
  })
  showOptions.value = false
}

function DeleteColumn(column) {
  Gems.Telegram(MESSAGES.QUESTIONS.REMOVE_COLUMN_TREE, "tele-info", "", "tele-cancel").then(
    () => {
      KNOLIA.CallBackEnd("post", "delete-column", {
        column: column,
        tree: "tasks",
      }).then((res) => {
        if (res.error) {
          Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed")
          return
        }

        const treeIndex = store.state.trees.tree.findIndex(
          (tree) => tree.id == store.state.trees.selectedMeta.id
        );

        const levelIndex = store.state.trees.tree[treeIndex].levels.findIndex(
          (level) => level.id == column.id
        );

        store.state.trees.tree[treeIndex].levels.splice(levelIndex, 1);

        Gems.Telegram(MESSAGES.SUCCESS.REMOVED, "tele-success")
        store.dispatch("UpdateTasksTree")
      });
    },
    () => {}
  );
}


function ShowOptions() {
  if(props.column.depth > props.column.totalDepth - 2 && !props.is_knowledge)
    return

  showOptions.value = true
}

function NewColumn() {
  store.dispatch('NewColumn', {meta_id: props.column.meta_id, depth: props.column.depth, totalDepth: props.column.totalDepth, elements: props.column.elements, treeType: TreeType.TASKS})

}

onMounted(() => {
  // Object.assign(props.column, { showSelf: KNOLIA.ToBoolean(props.column.showSelf)})

  store.commit('setColumnsTooltip', props.column)
});

onUpdated(() => {
  store.commit('setColumnsTooltip', props.column)
});
</script>

<template>
  <div class="depth-container" :class="column.depth <= column.totalDepth - 2 ? 'depth-container-bg':''">
    <div class="depth-header">
      <div class="div-column-icons" :class="column.depth > column.totalDepth - 2 ? 'special-column-icons':''">
        <i class="far fa-square-plus i-add-meta" :id="`new-column${column.id}`" @click="NewColumn()" v-if="column.depth <= column.totalDepth - 1"></i>

        <i class="fa fa-trash remove-col" :id="`remove-column${column.id}`" @click="DeleteColumn(column)" v-if="column.depth <= column.totalDepth - 2"></i>
      </div>

      <span class="depth" @dragover.prevent @click="store.commit('showAllElements', {depth: column.depth, meta_id: column.meta_id})"
        :id="`depth${column.depth}`">
        Nível {{ column.depth }}
      </span>

    </div>
    <div class="depth-second-header">
      <input
        :class="column.depth <= column.totalDepth - 2 ? 'col-name' : 'col-name depth-name'"
        ref="txtDepthName"
        type="text"
        :disabled="column.depth > column.totalDepth - 2"
        :value="column.designation"
        :readonly="!showOptions"
        @dblclick="ShowOptions()"
        placeholder="[Nome do nível]"
      />
      <div class="div-menu-edit" v-show="showOptions">
        <i class="fa fa-check confirm" :id="`confirm-column-name-icon-${props.column.id}`" @click="ChangeDepthName(column)"></i>
        <i class="fa fa-times cancel" :id="`cancel-column-name-icon-${props.column.id}`" @click="showOptions = false"></i>
      </div>
    </div>
  </div>
  <div class="elements-container">
    <span v-for="(element, index) in column.elements" :key="index" class="meta-comp-item" :class="{show: element.showSelf == true || column.depth == 1}">
      <DepthTasksElement
        :element="element"
        :parent="column.id"
        :columnDepth="column.totalDepth"
        :meta_id="column.meta_id"
        :depth="column"
        :index="index"
        v-if="element.showSelf == true || props.column.depth == 1"
        @filter-children="emit('filter-children', $event)"
      />
    </span>
  </div>
</template>

<style scoped>
  @import './assets/styles/trees-style.css';
</style>
