<!-- eslint-disable no-unused-vars -->
<script setup>
import { KNOLIA } from "@/knolia"
import { Gems } from "@/gems"
import { onMounted } from "@vue/runtime-core"
import ImportUploadFile from "../components/ImportUploadFile.vue"

async function Import(type) {
  const fileInput = document.getElementById(type)
  const file = document.getElementById(type).files[0]

  if (!file) {
    fileInput.click()
    return
  }

  let formData = new FormData()

  formData.append("file", file)
  formData.append("type", type)

  if (type == "activities" || type == "skills") {
    const children_name = type == "activities" ? "tarefas" : "competências operativas"

    await Gems.Telegram(
      `Criar ${children_name} automaticamente? (Prima 'Ok' para sim, 'Cancel' para não)`,
      "tele-info",
      "",
      "tele-cancel"
    ).then(
      () => {
        formData.append("children", true);
      },
      () => {
        formData.append("children", false);
      }
    );
  }

  const response = await KNOLIA.CallBackEnd("post", "import", formData);
  if (response.data.error) {
    Gems.Telegram(response.data.message, "tele-not-allowed")
    if (response.data.excel)
      Gems.ExportExcel(response.data.data)

    return
  }
  Gems.Telegram(response.data.message, "tele-success")

  document.getElementById(type).value = null
}

async function Export(type, table = null) {
  const res = await KNOLIA.CallBackEnd("post", "export", {
    type,
    table,
  })
  Gems.ExportExcel(res.data)
}

function ShowWarning(text, id) {
  const divWarning = document.getElementById(id);

  if (divWarning.children.length > 1) {
    CloseWarning(id)
  } else {
    const div = document.createElement("div");
    divWarning.appendChild(div);

    div.innerHTML += `<button class='fas fa-times close-import' onclick='document.getElementById(${id}).removeChild(document.getElementById(${id}).lastElementChild)'></button>`;
    div.innerHTML += text;
    div.innerHTML += '<li>Apenas importe ficheiros que foram exportados deste interface.</li>'
    div.innerHTML += '<li>Não apague colunas do EXCEL exportado.</li>'
    div.innerHTML += '<li>Os dados NÃO deverão conter o caracter ";" (ponto e vírgula).</li>'
    div.innerHTML += '<li>Grave o ficheiro com o seguinte formato:</li>'
    div.innerHTML += "<div class='excel-img'>";
    div.setAttribute("class", "div-import-help import-warning");
  }
}

function CloseWarning(id) {
  document
    .getElementById(id)
    .removeChild(document.getElementById(id).lastElementChild);
}

onMounted(() => { });
</script>
<template>
  <div class="page-container">
    <div class="information-check">
      <div class="information-checkbox not-editable"></div>
      <p class="information-text">
        Campos que não podem ser modificados/atualizados depois de carregados na
        Base de Dados
      </p>
    </div>
    <div class="information-check last">
      <div class="information-checkbox red"></div>
      <p class="information-text">
        Campos de dados obrigatórios e intransmissíveis
      </p>
    </div>

    <div class="group">
      <div id="2" class="warning">
        <div class="icon-container">
          <i class="fas fa-info-circle icon" @click="
            ShowWarning(`<li>Se o campo '1.' não existir na base de dados, o registo será inserido. Se existir, será atualizado.</li>`, 2)
            "></i>
          <span>Regras para importação</span>
        </div>
      </div>

      <!-- SKILLS AND OP SKILLS AREA -->
      <button @click="Export(1, 'skills')" class="button">
        EXPORTAR COMPETÊNCIAS
      </button>
      <table class="ex-table">
        <thead>
          <tr>
            <th class="not-editable">1. Código da competência</th>
            <th>2. Descritor</th>
            <th>3. Inativo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ex.: 1</td>
            <td>Ex.: Coordenação</td>
            <td>Ex.: 1</td>
          </tr>
          <tr>
            <td>(campo numérico)</td>
            <td>(campo alfa-numérico)</td>
            <td>(campo numérico)</td>
          </tr>
        </tbody>
      </table>
      <div class="upload-file">
        <ImportUploadFile :tableName="'skills'" @import="Import($event)" />
      </div>

      <button @click="Export(2, 'skillsOp')" class="button">
        EXPORTAR COMPETÊNCIAS OPERATIVAS
      </button>
      <table class="ex-table">
        <thead>
          <tr>
            <th class="not-editable">1. Código da competência operativa</th>
            <th>2. Código da competência</th>
            <th>3. Descritor</th>
            <th>4. Inativo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ex.: 1</td>
            <td>Ex.: 2</td>
            <td>Ex.: Coordenação</td>
            <td>Ex.: 1</td>
          </tr>
          <tr>
            <td>(campo numérico)</td>
            <td>(campo numérico)</td>
            <td>(campo alfa-numérico)</td>
            <td>(campo numérico)</td>
          </tr>
        </tbody>
      </table>
      <div class="upload-file">
        <ImportUploadFile :tableName="'skillsOp'" @import="Import($event)" />
      </div>

      <button @click="Export(3, 'activities')" class="button">
        EXPORTAR ATIVIDADES
      </button>
      <table class="ex-table">
        <thead>
          <tr>
            <th class="not-editable">1. Código da atividade</th>
            <th>2. Descritor</th>
            <th>3. Inativo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ex.: 1</td>
            <td>Ex.: Coordenação</td>
            <td>Ex.: 1</td>
          </tr>
          <tr>
            <td>(campo numérico)</td>
            <td>(campo alfa-numérico)</td>
            <td>(campo numérico)</td>
          </tr>
        </tbody>
      </table>
      <div class="upload-file">
        <ImportUploadFile :tableName="'activities'" @import="Import($event)" />
      </div>

      <button @click="Export(4, 'tasks')" class="button">
        EXPORTAR TAREFAS
      </button>
      <table class="ex-table">
        <thead>
          <tr>
            <th class="not-editable">1. Código da tarefa</th>
            <th>2. Código da atividade</th>
            <th>3. Descritor</th>
            <th>4. Inativo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ex.: 1</td>
            <td>Ex.: 2</td>
            <td>Ex.: Coordenação</td>
            <td>Ex.: 1</td>
          </tr>
          <tr>
            <td>(campo numérico)</td>
            <td>(campo numérico)</td>
            <td>(campo alfa-numérico)</td>
            <td>(campo numérico)</td>
          </tr>
        </tbody>
      </table>
      <div class="upload-file">
        <ImportUploadFile :tableName="'tasks'" @import="Import($event)" />
      </div>

    </div>

    <div class="group half">
      <div id="3" class="warning">
        <div class="icon-container">
          <i class="fas fa-info-circle icon" @click="
            ShowWarning(`<li>A tabela inteira será substituída. Todos os registos que não existirem no ficheiro carregado serão perdidos.</li>`, 3)
            "></i>
          <span>Regras para importação</span>
        </div>
      </div>

      <button @click="Export(8)" class="button">EXPORTAR CONSTITUIÇÃO DAS EQUIPAS</button>
      <table class="ex-table">
        <thead>
          <tr>
            <th class="not-editable">1. Código da equipa</th>
            <th>2. N° Interno</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ex.: 1</td>
            <td>Ex.: A020</td>
          </tr>
          <tr>
            <td>(campo numérico)</td>
            <td>(campo alfa-numérico)</td>
          </tr>
        </tbody>
      </table>
      <div class="upload-file">
        <ImportUploadFile :tableName="'teams'" @import="Import($event)" />
      </div>
    </div>
  </div>
</template>

<style>
.blink-import {
  animation: blink-import 1s step-start infinite;
}

@keyframes blink-import {
  50% {
    background-color: var(--default-font-color);
    color: #2361C2;
  }
}

.div-import-help {
  font-size: 0.8em;
  background-color: #ffffffd0;
  box-shadow: 0px 0px 5px 3px #00000020;
  backdrop-filter: blur(10px);
  position: absolute;
  left: 30px;
  height: 95px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 10;
}

.import-warning {
  top: 0px !important;
  text-align: initial;
  box-shadow: 0px 0px 5px 1px #000000;
  height: 475px !important;
  min-height: 20px;
  max-width: 610px;
  min-width: 290px;
  cursor: default;
}

.import-warning li {
  width: 95%;
}

.close-import {
  position: absolute !important;
  top: 4px;
  right: 15px;
  font-size: 1.3em !important;
  cursor: pointer;
  border: none !important;
  width: 10px !important;
  height: 10px !important;
  background-color: transparent;
}

.close-import:hover {
  color: var(--default-app-hover-color) !important;
  position: absolute;
  background-color: transparent !important;
}

.excel-img {
  display: block;
  height: 380px;
  width: 612px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/excel.png");
}
</style>

<style scoped>

.page-container {
  padding: 1% 2% 1% 1%;
}

.information-check {
  display: flex;
  align-items: center;
}

.last {
  margin-bottom: 10px;
}

.information-checkbox {
  width: 22px;
  height: 22px;
  border-radius: 4px;
}

.information-text {
  font-size: 9pt;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
}

.upload-file {
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
  align-items: center;
}

.ex-table {
  width: 100%;
  height: 100px;
  text-align: center;
  word-wrap: break-word;
  border: 1px solid #fff;
  border-collapse: separate;
  border-left: 0;
  border-radius: 25px !important;
  border-spacing: 0px;
}

.ex-table thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
}

.ex-table thead tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.ex-table th,
.ex-table tbody td {
  padding: 5px 4px 6px 4px;
  text-align: left;
  vertical-align: top;
  border-left: 1px solid;
}

.ex-table tbody td {
  border-top: 1px solid;
}

.ex-table thead:first-child tr:first-child th:first-child,
.ex-table tbody:first-child tr:first-child td:first-child {
  border-radius: 25px 0 0 0;
}

table.ex-table thead:last-child tr:last-child th:first-child,
table.ex-table tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 25px;
}

.half {
  width: 50% !important;
}

.ex-table td,
.ex-table th {
  padding: 3px 2px;
}

.ex-table thead th,
.ex-table tbody td {
  font-size: 12px;
  text-align: center;
  border-color: #fff;
  vertical-align: middle;
}

.ex-table thead th {
  font-weight: bold;
}

.ex-table thead th:first-child {
  color: #10133D;
}

.button {
  width: 100%;
  margin-bottom: 2px;
  font-size: 13px;
}

.button:not(:first-of-type) {
  margin-top: 30px;
}

.button:first-of-type {
  margin-top: 10px;
}

.group {
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid;
  background-color: #003e7825;
  color: #fff !important;
  border: none;
}

.warning {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon-container {
  display: flex;
  align-items: center;
}

.icon-container>span {
  font-weight: bold;
}

.icon {
  font-size: 1.3em;
  cursor: pointer;
  margin-right: 5px;
}

.red {
  background-color: orangered;
}

.not-editable {
  background-color: var(--default-app-hover-color) !important;
}
</style>
