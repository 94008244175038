/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router"
import SkillsTree from "../views/SkillsTree.vue"
import TasksTree from "../views/TasksTree.vue"
import Privacy from "../views/Privacy.vue"
import Admin from "../views/Admin.vue"
import Import from "@/views/Import.vue"
import Home from "@/views/Home.vue"
import SearchUsers from "@/views/SearchUsers.vue"
import JobTasks from "@/views/JobTasks.vue"
import SkillsControl from "@/views/SkillsControl.vue"
import Users from "@/views/Users.vue"
import MyKnolia from "@/views/MyKnolia.vue"
import Management from "@/views/Management.vue"
import SkillCard from "@/views/SkillCard.vue"
import UserActivityControl from "@/views/UserActivityControl.vue"
import Permissions from "@/views/Permissions.vue"
import { KNOLIA } from "@/knolia"
import { Gems } from "@/gems"

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      subMenu: true,
    },
    children: [
      {
        path: "/import",
        name: "Import",
        component: Import,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          principalNode: "/admin",
          subMenu: false,
        },
      },
      {
        path: "/skills-tree",
        name: "SkillsTree",
        component: SkillsTree,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          principalNode: "/admin",
          subMenu: false,
        },
      },
      {
        path: "/tasks-tree",
        name: "TasksTree",
        component: TasksTree,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          principalNode: "/admin",
          subMenu: false,
        },
      },
      {
        path: "/search-users",
        name: "SearchUsers",
        component: SearchUsers,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          principalNode: "/admin",
          subMenu: false,
        },
      },
      {
        path: "/job-tasks",
        name: "JobTasks",
        component: JobTasks,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          principalNode: "/admin",
          subMenu: false,
        },
      },
    ],
  },
  {
    path: "/my-knolia",
    name: "MyKnolia",
    component: MyKnolia,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      subMenu: true,
    },
  },
  {
    path: "/rank-skills",
    name: "RankSkills",
    component: SkillsControl,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/validate-skills",
    name: "ValidadeSkills",
    component: SkillsControl,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/skill-card",
    name: "SkillCard",
    component: SkillCard,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/management",
    name: "Management",
    component: Management,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      subMenu: true,
    },
  },
  {
    path: "/user-activity-control",
    name: "UserActivityControl",
    component: UserActivityControl,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: Permissions,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


async function Authentication() {
  let res = await KNOLIA.CallBackEnd("post", "router-check").then((response) => {
    if (response.error) {
      localStorage.setItem("auth", JSON.stringify({ value: false }))
      localStorage.setItem("admin", JSON.stringify({ value: 0 }))

      return false
    }

    const item = { value: response.data['status'] == 5 ? true : false }
    const admin = { value: response.data['admin'] != null ? response.data['admin'] : 0 }
    const entity = { value: response.data['entity'] != null ? response.data['entity'] : 0 }

    localStorage.setItem("auth", JSON.stringify(item))
    localStorage.setItem("admin", JSON.stringify(admin))
    localStorage.setItem("entity", JSON.stringify(entity))
    localStorage.setItem("cycle", response.data['cycle'])

    return [item.value, admin.value, entity.value, response.data.validate_notif]
  })

  return res
}

async function ConfirmToken(token, entity) {
  let res = await KNOLIA.CallBackEnd("post", "pass-recovery", {
    token: token,
    entity: entity,
  }).then((response) => {
    if (response.data[0] == true)
      return [true, response.data[3]]
    else
      return [false, response.data]
  });

  return res;
}

function CheckForAdmin(adminRequired, admin, next) {
  if (adminRequired && !admin) {
    next("/rank-skills");
  } else {
    next();
  }
}

router.afterEach(() => {
  document.body.scrollTop = 0;
});

router.beforeEach(async (to, from, next) => {
  const res = await Authentication()
  const authRequired = to.matched.some((route) => route.meta.requiresAuth)
  const adminRequired = to.matched.some((route) => route.meta.requiresAdmin)
  let authenticated = JSON.parse(localStorage.getItem("auth")).value
  let admin = KNOLIA.isAdmin()

  document.title = "KNOLIA"
  Object.assign(to.params, { validateNotif: res[3] })

  switch (to.path) {
    case '/':
      if (from.path == '/') {
        let checked = await KNOLIA.CallBackEnd("get", "check-credentials")

        if (checked?.data?.status == 0) {
          const redirectEncode = window.btoa(`${Gems.EnvPrefix()}knolia.inpeoplehub.com`)
          window.location.href = `${Gems.HubUrl()}?${redirectEncode}`
        } else {
          next("/rank-skills")
        }
      }
      break;
    case "/recovery":
      const url = new URL(window.location.href)
      if (url.search == '')
        next("/")

      const path = url.search.split("?")[1]
      const [entity, token] = path.split("&")

      if (!token)
        next("/")

      const response = await ConfirmToken(token, entity)

      if (response[0] == true)
        Object.assign(to.params, { username: response[1][0]["username"] })
      else
        Object.assign(to.params, { error: response[1] })

      next()
      break;
    case '/validate-skills':
      Object.assign(to.params, { validateSkills: true })
      next();
      break;
    default:
      if (authRequired && !authenticated)
        next("/")

      CheckForAdmin(adminRequired, admin, next)
  }
});

export default router;