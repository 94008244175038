<script setup>

import { KNOLIA } from '@/knolia';
import { onMounted, ref, watch } from 'vue';
import Activities from '../components/skill-card/Activities.vue';

const userData = ref({})
const expandedCriterias = ref({ activities: [], extra_activities: [] })
const knowledges = ref(null)
const defaultImage = ref(require('@/images/default.png'))
let showAll = ref(false)

onMounted(async () => {
    const response = await KNOLIA.CallBackEnd('get', `get-user`)

    userData.value = response.data.user

    const responseSkillCard = await KNOLIA.CallBackEnd('get', `get-skill-card`)

    expandedCriterias.value.activities = responseSkillCard.data.activities
    expandedCriterias.value.extra_activities = responseSkillCard.data.extra_activities
    knowledges.value = responseSkillCard.data.knowledges
})

watch(showAll, (e) => {
    if (e)
        document.querySelectorAll(".fa-chevron-down").forEach(elem => elem.click())
    else
        document.querySelectorAll(".fa-chevron-up").forEach(elem => elem.click())
})

</script>


<template>
    <div class="div-container">
        <div class="div-header-container">
            <div id="div-user-header">
                <div id="div-user-photo">
                    <img id="div-logo" class="img-user-photo" :src="userData.src" @error="$event.target.src = defaultImage">
                </div>

                <div id="div-user-info">
                    <div id="div-name">
                        <strong>{{ userData.complete_name }}</strong>
                    </div>
                    <div><strong>Número Interno:</strong> {{ userData.user_internal }}</div>
    
                    <div id="div-teams">
                        <strong>Equipas: </strong>
                        <div class="div-team" v-for="team in userData.assigned_teams" :key="team.id">
                            {{ team.designation }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        
        <div v-if="expandedCriterias.activities.length > 0 || expandedCriterias.extra_activities.length > 0" id="div-skills-data">
            <div class="show-all-container cursor-pointer" @click="showAll = !showAll">
                <i class="icon fas" :class="{ 'fa-plus-square': !showAll, 'fa-minus-square': showAll }" />
                <span>Expandir/Fechar tudo</span>
            </div>

            <Activities v-if="expandedCriterias.activities.length > 0" :expanded-criterias="expandedCriterias.activities" :is-activities-job="true">
                <template #title>
                    <div class="function-title">Função: {{ userData.user_job }}</div>
                </template>
            </Activities>

            <Activities v-if="expandedCriterias.extra_activities.length > 0" :expanded-criterias="expandedCriterias.extra_activities" :is-activities-job="false">
                <template #title>Atividades fora do âmbito da função</template>
            </Activities>

            <div class="div-job-activity-name">
                Conhecimentos
            </div>

            <div class="div-knowledge" v-for="knowledge in knowledges" :key="knowledge.id">
                {{ knowledge.designation }}
            </div>
        </div>
    </div>
</template>

<style scoped>

.div-job-activity-name {
    margin: 10px 0;
    font-weight: bold;
}

.div-knowledge {
    background-color: var(--default-app-alt-line-color);
    padding: 5px;
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.function-title {
    font-size: 1.2em;
}

.div-container {
    width: 98%;
    height: 100%;
    padding: 1%;
}

#div-teams {
    display: flex;
    gap: 5px;
}

.div-team + * {
    padding-left: 10px;
    border-left: 1px solid black;
}

#div-user-content {
    background-color: white;
    padding: 20px;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
}

#div-user-header {
    font-size: 1.3em;
    display: flex;
    align-items: center;
    gap: 30px;
}

#div-skills-data {
    border-top: 1px solid;
    padding-top: 20px;
    margin-top: 20px;
}

#div-name {
    display: flex;
    justify-content: space-between;
}

.img-user-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.div-header-container {
    display: flex;
}

.show-all-container i {
    margin-right: 10px;
}

.show-all-container:hover {
    color: var(--default-hover-color);
}
</style>
