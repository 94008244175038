<!-- eslint-disable no-undef -->
<script setup>
import { onMounted, onUpdated, ref } from "vue"
import { useStore } from "vuex"
import { KNOLIA } from "../knolia"
import { Gems } from "../gems"
import TreeType from "@/enums/TreeType"
import { MESSAGES } from "../messages"


const props = defineProps(["element", "parent", "meta_id", "columnDepth", "is_meta", "depth", "index"])
const store = useStore()
const showChilds = ref(false)
let inputNameRef = ref()
let showOptions = ref(false)

function ChangeMetaOrElement() {
  Object.assign(props.element, {selected: !props.element.selected})
  
  if (props.is_meta == true) 
    store.commit("setSelectedMeta", props.element.selected ? props.element : [])
  else
    store.dispatch("FilterChildren", [props.element, props.element.selected, props.meta_id])
}

async function AddElementFromSkillsList(elem) {
  elem["parent_id"] = props.element.id
  elem["depth"] = parseInt(props.element.depth, 10) + 1

  if (props.element.depth < props.element.totalDepth - 1) {
    Gems.Telegram(MESSAGES.ERROR.NOT_ALLOWED, "tele-not-allowed")
    return
  }

  await KNOLIA.CallBackEnd("post", "control-tree-element", {
    element: elem,
    parent: props.element,
    update: "skills-on-task",
    treeType: TreeType.TASKS,
  }).then(() => {
    store.dispatch("UpdateTasksTree")
    Gems.Telegram(MESSAGES.SUCCESS.INSERTED, "tele-success")
  })
}

async function AddElementFromActivities(elem) {
  const checkDepth = props.element.totalDepth >= 2 ? props.element.totalDepth - 2 : 1
  elem["parent_id"] = props.element.id;
  elem["depth"] = parseInt(props.element.depth, 10) + 1;

  if (props.element.depth != checkDepth) {
    Gems.Telegram(MESSAGES.ERROR.NOT_ALLOWED, "tele-not-allowed")
    return
  }

  await KNOLIA.CallBackEnd("post", "control-tree-element", {
    element: elem,
    parent: props.element,
    treeType: TreeType.TASKS,
    update: "tasks",
  }).then(() => {
    store.dispatch("UpdateTasksTree")
    Gems.Telegram(MESSAGES.SUCCESS.INSERTED, "tele-success")
  });
}

async function Drop(event, element) {
  const newItem = JSON.parse(event.dataTransfer.getData("element"))
  newItem["selected"] = false
  newItem["showSelf"] = element.selected

  if (newItem.fromSkillsList || newItem.fromSkillsOpList) {
    AddElementFromSkillsList(newItem)
    return
  } 
  
  if (newItem.fromElementList) {
    AddElementFromActivities(newItem)
    return
  }
    
  if (element.totalDepth - 2 <= 0 || newItem.depth < parseInt(element.depth, 10) + 1 || newItem.id == element.id) {
    Gems.Telegram(MESSAGES.ERROR.NOT_ALLOWED, "tele-not-allowed")
    return
  }

  await KNOLIA.CallBackEnd("post", "update-tree-position", {
    element: newItem,
    target: element,
    to: "element",
    treeType: TreeType.TASKS,
  }).then(() => {
    store.dispatch("UpdateTasksTree", [newItem])
    Gems.Telegram(MESSAGES.SUCCESS.CHANGED, "tele-success")
  })
}

function OptionsHandler(status) {
  if (!status) {
    inputNameRef.value.setAttribute("readonly", !status);
    inputNameRef.value.classList.add("pointer");
  } else {
    inputNameRef.value.removeAttribute("readonly");
    inputNameRef.value.classList.remove("pointer");
  }
  showOptions.value = status;
}

function ChangeInput(element) {
  if (element.is_activity || element.is_task)
    return

  if(element.new)
    element.designation = ''

  OptionsHandler(true)
}

function ElementConfirm() {
  OptionsHandler(false)
  store.dispatch("UpdateElementName", {
    name: inputNameRef.value.value,
    update: props.is_meta ? "meta" : "element",
    treeType: TreeType.TASKS,
    element: props.element
  })
}

function ElementCancel() {
  if (props.element.new) {
    store.commit("removeElementAndChilds", props.element)
  } else {
    OptionsHandler(false);
    inputNameRef.value = props.element.description;
  }
}

function Remove(element) {
  Gems.Telegram(MESSAGES.QUESTIONS.REMOVE_FROM_TREE, "tele-info", "", "tele-cancel")
    .then(
      async () => {
        if (element.is_skill_op) {
          KNOLIA.CallBackEnd("post", "remove-skill-from-task", {
            element: element,
          }).then(() => {
            store.dispatch("UpdateTasksTree")
          })

        } else if (element.is_activity) {
          element.relational_id = element.id;
          store.dispatch("DissociateTasksElement", [element, "skill"])
            .then(() => {
              store.dispatch("UpdateTasksTree");
            });
        } else {
          store.dispatch("DissociateTasksElement", [element, "element"])
            .then(() => {
              // store.dispatch("UpdateTasksTree");
              store.commit('removeElementAndChilds', element)
            });

          if(props.is_meta && props.meta_id == element.meta_id) 
            store.commit('setSelectedMeta', [])

        }
      },
      () => {}
    );
}

async function CreateElem() {
  if(!props.element.selected)
    Object.assign(props.element, {selected: !props.element.selected})

  if (props.is_meta == true)
    if(props.element.levels.length == 2)
      await store.dispatch('NewColumn', {meta: props.element, meta_id: props.element.meta_id, depth: parseInt(props.element.depth, 10) + 1, elements: [], treeType: TreeType.TASKS})

  KNOLIA.NewElem(store, props.element, 'insertChild')

  if (props.is_meta == true) 
    store.commit("setSelectedMeta", props.element)
  else
    store.dispatch("FilterChildren", [props.element, true, props.meta_id])
}

onMounted(() => {
  Object.assign(props.element, { selected: KNOLIA.ToBoolean(props.element.selected) })
  Object.assign(props.element, { is_skill_op: KNOLIA.ToBoolean(props.element.is_skill_op) })
  
  const parentName = document.getElementById(`parent-name${props.element.id}`)

  if(parentName)
    Gems.Tooltip(parentName, 'Pai', 'tooltip')

  store.commit('setElementsTooltips', props.element)
});

onUpdated(() => {
  store.commit('setElementsTooltips', props.element)
});
</script>

<template>
  <div class="div-depth-element">

    <span v-if="props.element.parent_designation" class="parent-name" :id="`parent-name${props.element.id}`">{{ props.element.parent_designation }}</span>

    <div class="element-row default-radius" :class="{'opened-row': element.selected || showChilds, 'row-task': props.element.is_task}" :id="`${props.element.designation + props.element.id}`">
      
      <i class="fas cursor-pointer show-childs" :class="{'fa-plus': !element.selected, 'fa-minus': element.selected}" :id="`checkbox${props.element.id}`"
          v-if="(!props.element.is_task && !props.element.is_skill_op && props.element.has_children > 0) || props.is_meta"
          @click="ChangeMetaOrElement"
      ></i>
      <div v-else-if="!props.element.is_task" class="checkbox-div"></div>

      <input readonly="true" ref="inputNameRef" class="input"
        :draggable="KNOLIA.IsDraggable(props.element, props.columnDepth, showOptions)"
        :value="element.designation" :title="props.element.designation"
        @dragstart="KNOLIA.Drag($event, props.element)"
        @dragover.prevent
        @drop="Drop($event, props.element)"
        @dblclick="ChangeInput(element)"
      />

      <div class="div-menu-edit" v-show="showOptions || props.element.new">
        <i class="fa fa-check confirm" :id="`confirm-elem-name${element.id}`" @click="ElementConfirm"></i>
        <i class="fa fa-times cancel" :id="`cancel-elem-name${element.id}`" @click="ElementCancel"></i>
      </div>

      <div class="element-std-icons">
        <i class="fa fa-plus-circle i-add-elem" :id="`new-elem${props.element.id}`"
          v-show=" !showOptions && !props.element.new && (props.element.depth < props.element.totalDepth - 2 || props.is_meta)"
          @click="CreateElem"
        ></i>
  
        <i class="far fa-trash-can remove-icon" :id="`delete${props.element.id}`"
          v-if="!props.element.is_task && !props.element.new"
          v-show="!showOptions" @click="Remove(props.element)"
        ></i>
  
        <i class="fa fa-list-ul list-icon" :id="`skills-op${props.element.skill_id}`"
          v-if="props.element.is_task && !props.element.new && props.element.children.length > 0" @click="showChilds = !showChilds"
          ></i>
      </div>
    </div>

    <div v-if="props.element.is_task && showChilds" :id="`div-skills-op${props.element.id}`" class="element-children-list">
      <div v-for="child of element.children" :key="child.id">
        <div style="display: flex; justify-content: space-between;">
          <span>{{ child["designation"] }}</span>
          <i class="fa-regular fa-circle-xmark cursor-pointer" @click="Remove(child)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  @import './assets/styles/trees-style.css';

</style>
