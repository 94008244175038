/* eslint-disable prettier/prettier */
// import { Gems } from "@/gems";
import { KNOLIA } from "@/knolia";

const defaultTree = () => {
    return {
        user: [],
    };
};

const state = {
    user: [],
};

const getters = {
    User: (state) => state.user,
};

const actions = {

    async GetUser({ commit }) {
        KNOLIA.SetRequestedUserId(KNOLIA.GetLoggedUserId())
        const res = await KNOLIA.CallBackEnd("get", "get-user")

        commit('setUser', res.data.user)
    },

};

const mutations = {
    setUser: (state, user) => {
        state.user = user;
    },

    resetUser(state) {
        Object.assign(state, defaultTree());
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
