<script setup>
import { onMounted, onUpdated, defineEmits } from "vue"
import { ref } from "@vue/reactivity"
import { KNOLIA } from "@/knolia"
import { Gems } from "@/gems"
import { MESSAGES } from "@/messages";

const emits = defineEmits(['control-modal'])

const rankList = ref([])
const ddlDepth = ref([])
const filter = ref("")
let rankType = ref(1)
let selectedDDL = ref(null)

const tooltipKnowledge = ref({1: 'Conhece', 2: 'Conhece', 3: 'Compreende', 4: 'Compreende', 5: 'Integra', 6: 'Integra'})
const tooltipSkillOp = ref({1: 'Usa', 2: 'Usa', 3: 'Aplica', 4: 'Aplica', 5: 'Transforma', 6: 'Transforma'})

async function GetDepthDDL() {
  KNOLIA.CallBackEnd("get", "get-depth-ddl").then((res) => {
    ddlDepth.value = res.data;
  });
}

async function GetRankSkills() {
  KNOLIA.CallBackEnd("get", "get-rank-skills", {
    params: {
      filter: filter.value,
      rankType: rankType.value,
      ddlSelected: selectedDDL.value,
    },
  }).then((res) => {
    rankList.value = res.data;
  });
}

async function RequestValidation(element) {
  if (!element.rating) {
    Gems.Telegram(MESSAGES.ERROR.RATE_ABILITY, 'tele-not-allowed')
    return
  }

  const response = await KNOLIA.CallBackEnd("post", "control-rating-abilities", {
    elem: element,
    type: 'request-validation'
  })
  
  element.waiting_validation = true
  
  if(response.error)
    GetRankSkills()
}

function CheckForTooltip(ev, index, element) {
  if(element.is_skill_op)
    Gems.Tooltip(ev.target, tooltipKnowledge.value[index], 'tooltip')
  else
    Gems.Tooltip(ev.target, tooltipSkillOp.value[index], 'tooltip')
}

async function DefineRating(element) {
  const response = await KNOLIA.CallBackEnd("post", "control-rating-abilities", {
    elem: element,
    type: 'define-rating'
  })

  if(response.error)
    GetRankSkills()
}

function SetRating(element, rating) {
  if(KNOLIA.ToBoolean(element.waiting_validation))
    return

  if(element.rating == rating) 
    rating = null

  element.rating = rating
  DefineRating(element)
}

function DefineEyeIconStatus(elem) {
  let cssClass = 'fas'
  
  if(!KNOLIA.ToBoolean(elem.has_files) || !KNOLIA.ToBoolean(elem.has_comments))
    cssClass = 'far'

  if(KNOLIA.ToBoolean(elem.has_unread_messages))
    cssClass += ' new-message-animate'

  return cssClass
}


onMounted(() => {
    GetRankSkills()
    GetDepthDDL()
});

onUpdated(() => {
  for (let skill in rankList.value) {

    const sitemap = document.getElementById(`skill${skill}`)
    if(sitemap)
      Gems.Tooltip(sitemap, rankList.value[skill].sitemap, "tooltip tooltip-left")

    if (rankList.value[skill]["waiting_validation"]) {
      const validation = document.getElementById(`validation${skill}`)
      if(validation)
        Gems.Tooltip(validation, "Provisório", "tooltip")
    }
    
    const showModal = document.getElementById(`show-modal${skill}`)
  
    if(showModal)
      Gems.Tooltip(showModal, 'Detalhes', 'tooltip')
  }

});
</script>

<template>
  <div>
    <div class="filter">
      <select class="cursor-pointer" v-model="selectedDDL">
        <option :value="null">Filtrar por pai</option>
        <option v-for="elem in ddlDepth" :key="elem.id" :value="elem.id"> {{ elem.designation }}</option>
      </select>
      <input class="search-elem" type="text" v-model="filter" />
      <div class="radios-selection">
        <div>
          <input class="cursor-pointer" type="radio" value="1" v-model="rankType">
          <label>Já classificadas</label>
        </div>
        
        <div>
          <input class="cursor-pointer" type="radio" value="2" v-model="rankType">
          <label>Por classificar</label>
        </div>
        
        <div>
          <input class="cursor-pointer" type="radio" value="3" v-model="rankType">
          <label>Todas</label>
        </div>
      </div>
      <button @click="GetRankSkills">FILTRAR</button>
    </div>
    <div class="rank-table">
      <div class="rank-grid-header">
        <span class="desig">Descrição</span>
        <span>Classificação</span>
        <span class="validateHeader"></span>
        <span>Validação</span>
      </div>

        <div v-for="(element, index) in rankList" :key="element" class="rank-grid" :class="{'bg-alt-line': index % 2 == 0}">
          <span :id="`skill${index}`" class="td-desig">
            {{ element.designation }}
          </span>
          <span class="radios">
            <div v-for="i in 6" :key="i" :class="element.rating >= i ? `opt-color-${i}` : ''" :id="`opt-${i}`" @mouseenter="CheckForTooltip($event, i, element)">
              <input :class="{'cursor-pointer': !KNOLIA.ToBoolean(element.waiting_validation)}" type="radio" 
                v-model="element.rating" :value="i" :disabled="KNOLIA.ToBoolean(element.waiting_validation) && element.rating != i"
                @click="SetRating(element, i)">
              <label for="">{{ i }}</label>
            </div>
          </span>
          <span class="modal-icon">
            <i class="fas fa-eye" :class="DefineEyeIconStatus(element)" :id="`show-modal${index}`" @click="emits('control-modal', {bool: true, elem: element, chat: false})"></i>
          </span>
          <span :id="`validation${index}`" :class="KNOLIA.ToBoolean(element.waiting_validation) ? 'temporary' : ''">
            {{ element.validation_designation }}
          </span>
          <span class="btn-validate">
            <button class="ddl-alt-padding" v-if="!KNOLIA.ToBoolean(element.waiting_validation)" @click="RequestValidation(element)">SOLICITAR VALIDAÇÃO</button>
            <span class="waiting-validation" v-else-if="KNOLIA.ToBoolean(element.waiting_validation)">VALIDAÇÃO EM PROGRESSO...</span>
          </span>
        </div>
    </div>
  </div>
</template>

<style scoped>
.filter {
  width: 100%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 20px;
  gap: 5px;
}

.filter select {
  width: 12%;
}

.search-elem {
  width: 40%;
}

.filter button {
  width: 13%;
}

.depth-ddl select {
  height: 100%;
  width: 140px;
  border: 1px solid grey;
}

.rank-table {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.rank-grid-header {
  font-weight: bold;
}

.rank-grid,
.rank-grid-header {
  display: grid;
  grid-template-columns: 38% 25% 5% 13% 15%;
  text-align: center;
  align-items: center;
  gap: 1%;
  padding: 1px;
}

.radios div input {
  margin-right: 6px;
  transform: scale(1.3);
}

.radios div:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding-left: 10px;
}


.radios div:last-child {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-right: 10px;
}

.radios div {
  display: inline-flex;
  align-items: center;
  padding: 3px;
  flex: 1;
  justify-content: center;
}

.radios {
  display: flex;
  font-size: 1.2em;
  justify-content: center;
}

.opt-color-1 {
  background-color: #0096B4 !important;
}

.opt-color-2 {
  background-color: #00AAA0 !important;
}

.opt-color-3 {
  background-color: #00BE8C !important;
}

.opt-color-4 {
  background-color: #00D278 !important;
}

.opt-color-5 {
  background-color: #00E664 !important;
}

.opt-color-6 {
  background-color: #00FF50 !important;
}

.rank-table tbody td button {
  height: 28px;
  line-height: 1px;
}

.td-desig {
  cursor: default;
  text-align: start;
  padding-left: 5px;
}

.modal-icon {
  font-size: 1.3em;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
}


.temporary {
  cursor: default;
}

.disabled {
  border-color: transparent;
  cursor: default;
}

.disabled:hover {
  border-color: transparent;
  background-color: transparent;
  color: inherit;
}

.radios-selection {
  display: flex;
  gap: 5px;    
  width: 30%;
  flex: auto;
  justify-content: space-evenly;
}

.radios-selection div {
  display: flex;
  align-items: center;
  align-self: center;
  gap: 3px;
}

.radios-selection input {
  margin-top: -1px;
  transform: scale(1.5);
}

/* .btn-validate {
  text-align: end;
} */

.waiting-validation {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85em !important;
}

@media only screen and (max-width: 1300px) {

  .search-elem {
    width: 25%;
  }

}

</style>
