<script setup>
import { onUpdated, ref } from 'vue'
import { KNOLIA }         from '@/knolia'
import { Gems }           from '@/gems'
import { MESSAGES }       from '@/messages'
import SearchCriteriaEnum from "@/enums/SearchCriteria"



const searchCriteriaInput  = ref(null)
const criterias            = ref([])
const users                = ref([])
const expandedCriterias    = ref({ activities: [], skills: [] })
const selectedCriteriaType = ref(null)
let listName               = ref('')

onUpdated(() => {
    document.querySelectorAll(`.div-user`).forEach(divUser => {
        const tooltipData = JSON.parse(divUser.getAttribute('tooltip-data'))
        let tooltipText = ''
        
        if(tooltipData == null)
            return 
            
        tooltipData.forEach(skill => {
            tooltipText += `${skill.skill_op_name} | ${skill.proficiency} | ${skill.validation_percent ?? '0.00'}%
            `
        })

        Gems.Tooltip(divUser, tooltipText, 'tooltip')
    })

    criterias.value.forEach(criteria => {
        let row = document.getElementById(`criteria-${criteria.id}`)
        
        if(row && criteria.sitemap)
            Gems.Tooltip(row, criteria.sitemap, 'tooltip')
    })
})





function ClearExpandedCriterias() {
    expandedCriterias.value.activities = []
    expandedCriterias.value.skills = []
    users.value = []
}

async function SearchCriterias() {
    if(selectedCriteriaType.value == null) {
        Gems.Telegram(MESSAGES.ERROR.TYPE_UNCHOSEN, 'tele-not-allowed')
        return 
    }

    criterias.value = []

    const response = await KNOLIA.CallBackEnd('post', 'search-criteria', {
        criteria: selectedCriteriaType.value,
        searchField: searchCriteriaInput.value.value
    })

    criterias.value = response.data
}

function GetSelectedSkillsOp() {
    return Array.from(document.querySelectorAll(`[skill-op-id]:checked`))
        .map(chk => chk.parentElement.parentElement)
        .map(divSkill => {
            return {
                skill_op_id: divSkill.querySelector(`[skill-op-id]`).getAttribute('skill-op-id'),
                proeficiency: divSkill.querySelector(`.sel-skill-op-proeficiency`).value
            }
        })
}

async function SearchUsers() {
    const skillOpsObj = GetSelectedSkillsOp()

    const response = await KNOLIA.CallBackEnd('post', 'search-users-by-criterias', {
        criterias: skillOpsObj,
    })

    users.value = response.data
}

async function DropExpandCriterias(event) {
    const criteria = JSON.parse(event.dataTransfer.getData('criteria'))

    const response = await KNOLIA.CallBackEnd('post', 'get-criteria-list', {
        id: criteria.id,
        type: criteria.type
    })

    if (Object.keys(response.data.activities).length > 0 && !HasDuplicated(expandedCriterias.value.activities, ...response.data.activities))
        expandedCriterias.value.activities.push(...response.data.activities)

    if (Object.keys(response.data.skills).length > 0 && !HasDuplicated(expandedCriterias.value.skills, ...response.data.skills))
        expandedCriterias.value.skills.push(...response.data.skills)


    function HasDuplicated(originalExpandedCriterias, newExpandedCriterias) {
        return originalExpandedCriterias.filter(c => c.id == newExpandedCriterias.id).length > 0
    }
}


function HoldCriteria(event, criteria) {
    event.dataTransfer.setData('criteria', JSON.stringify(criteria))
}

function CheckActivities(activityId) {
    //const chkActivity = document.querySelector(`[activity-id="${activityId}"] .div-activity input`)
    const chkTasks = document.querySelectorAll(`[parent-activity-id="${activityId}"] .div-task-input input`)

    chkTasks.forEach(chk => chk.click())
}

function CheckTasks(taskId) {
    // const chkTask = document.querySelector(`[task-id="${taskId}"] .div-task input`)
    const chkSkillOps = document.querySelectorAll(`[parent-task-id="${taskId}"] [skill-op-id]`)

    chkSkillOps.forEach(chk => chk.click())
}

function ChangeTaskProeficiency(task, assignmentLevel) {
    task.assignment_level = assignmentLevel
    task.assignment_criteria = task.assignment_criteria ?? 1

    let skillOps = []
    let selectedAssignment = task.assignment_ddl.filter(assignment => assignment.level == assignmentLevel)[0]

    selectedAssignment = task.assignment_criteria == 0 ? selectedAssignment.min : selectedAssignment.ideal

    expandedCriterias.value.skills.forEach(skill => {
        skill.children.forEach(skillOp => {
            if (skillOp.task_id == task.id)
                skillOps.push(skillOp)
        })
    })


    skillOps.forEach(skillOp => {
        if (assignmentLevel == 3)
            skillOp.proficiency_ddl[0].hide = true
        else
            skillOp.proficiency_ddl[0].hide = false

        skillOp.selected_proeficiency = skillOp.proficiency_ddl.filter(proef => selectedAssignment >= proef.min).reverse()[0].id
    })
}
function ChangeTaskProeficiencyCriteria(task, executionCriteria) {
    task.assignment_criteria = executionCriteria
    task.assignment_level = task.assignment_level ?? 1

    let skillOps = []
    let selectedAssignment = task.assignment_ddl.filter(assignment => assignment.level == task.assignment_level)[0]

    selectedAssignment = executionCriteria == 0 ? selectedAssignment.min : selectedAssignment.ideal

    expandedCriterias.value.skills.forEach(skill => {
        skill.children.forEach(skillOp => {
            if (skillOp.task_id == task.id)
                skillOps.push(skillOp)
        })
    })


    skillOps.forEach(skillOp => {
        skillOp.selected_proeficiency = skillOp.proficiency_ddl.filter(proef => selectedAssignment >= proef.min).reverse()[0].id
    })
}

function CheckSkills(skillId) {
    // const chkSkill = document.querySelector(`[skill-id="${skillId}"] .div-skill input`)
    const chkSkillOps = document.querySelectorAll(`[skill-id="${skillId}"] [skill-op-id]`)

    chkSkillOps.forEach(chk => chk.click())
}

function CheckSkillsOp(skillId) {
    const chkSkillOps = Array.from(document.querySelectorAll(`[skill-id="${skillId}"] [skill-op-id]`))
    const skillOpsChecked = chkSkillOps.filter(chk => chk.checked == true)
    const divSkillChk = document.querySelector(`[skill-id="${skillId}"] .div-skill input`)

    divSkillChk.checked = skillOpsChecked.length == chkSkillOps.length
    SearchUsers() // por enquanto
}

async function SaveList() {
    if(!listName.value){
        Gems.Telegram(MESSAGES.ERROR.LIST_UNNAMED, 'tele-not-allowed')
        return
    }

    const criterias = Array.from(document.querySelectorAll(`[skill-op-id]:checked`))
        .map(chk => chk.parentElement.parentElement)
        .map(divSkill => {
            const skillName = divSkill.querySelector(`[skill-op-id]`).nextSibling
            const skillDDL = divSkill.querySelector(`.sel-skill-op-proeficiency`)
            return `${skillName.textContent} [${skillDDL.options[skillDDL.value].text}]`
        })

    const response = await KNOLIA.CallBackEnd('post', 'save-users-list', {
        listName: listName.value,
        users: users.value,
        criterias
    })

    if(response.error) {
        Gems.Telegram(MESSAGES.ERROR.DEFAULT, 'tele-not-allowed')
        return
    }

    Gems.Telegram(MESSAGES.SUCCESS.SAVED, 'tele-success')
    listName.value = ''
}
</script>


<template>

    <div id="div-search-container">
        <div id="div-search-criterias-container">
            <div id="div-left-criteria">
                <div id="div-criterias">
                    <select v-model="selectedCriteriaType" class="select-criteria-type">
                        <option :value="null">Escolha o tipo</option>
                        <option :value="SearchCriteriaEnum.USERS">Pessoas</option>
                        <option :value="SearchCriteriaEnum.SKILLS">Competencia</option>
                        <option :value="SearchCriteriaEnum.ACTIVITIES">Atividades</option>
                        <option :value="SearchCriteriaEnum.JOBS">Funções</option>
                    </select>
                </div>
                <div class="div-search-criterias">
                    <input ref="searchCriteriaInput" type="text" placeholder="">
                    <button @click="SearchCriterias()">PROCURAR</button>
                </div>
            </div>

        </div>


        <div id="div-criteria-result-container-header">
            <div class="div-result-criterias">
                <label for="" class="group-name">Resultado da pesquisa</label>
            </div>
            <div class="div-expanded-criterias-header">
                <div class="criterias-title">
                    <label class="group-name criterias">Critérios</label>
                </div>
                <span class="group-name cursor-pointer clear"  @click="ClearExpandedCriterias()">Limpar</span>
            </div>
        </div>

        <div id="div-criteria-result-container">
            <div class="div-result-criterias">
                <div id="div-result-criterias" class="scrollbar">
                    <div draggable="true" class="div-criteria" :class="{'bg-alt-line': i % 2 == 0}" 
                        v-for="(criteria, i) in criterias" :key="criteria.id" @dragstart="HoldCriteria($event, criteria)"
                        :id="`criteria-${criteria.id}`">
                        {{ criteria.name }}
                    </div>
                </div>
            </div>

            <div class="div-expanded-criterias">
                <div id="div-expanded-criterias" class="scrollbar" @drop="DropExpandCriterias($event)" @dragenter.prevent
                    @dragover.prevent>

                    <div id="div-activities" class="div-criterias-header">
                        Atividades
                    </div>
                    <div v-for="activity in expandedCriterias.activities" :key="activity.id" :activity-id="activity.id">
                        <div class="div-activity bg-alt-line">
                            <input @click="CheckActivities(activity.id)" type="checkbox">{{ activity.name }}
                        </div>

                        <div class="div-task" v-for="(task) in activity.children" :key="task.id" :parent-activity-id="activity.id"
                            :task-id="task.id">

                            <div class="div-task-input">
                                <input @click="CheckTasks(task.id)" type="checkbox">{{ task.name }}
                            </div>

                            <div class="div-task-assignment-level">
                                <select class="sel-assignment-level ddl-alt-padding "
                                    @change="ChangeTaskProeficiency(task, $event.target.value)">
                                    <option v-for="assignment in task.assignment_ddl" :key="assignment.level"
                                        :value="assignment.level">{{ assignment.designation }}</option>
                                </select>

                                <select class="sel-proef-level ddl-alt-padding "
                                    @change="ChangeTaskProeficiencyCriteria(task, $event.target.value)">
                                    <option value="0">Min</option>
                                    <option value="1">Ideal</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div id="div-skills" class="div-criterias-header">
                        Competências (Critérios de pesquisa)
                    </div>
                    <div v-for="skill in expandedCriterias.skills" :key="skill.id" :skill-id="skill.id">
                        <div class="div-skill bg-alt-line">
                            <input @click="CheckSkills(skill.id)" type="checkbox">{{ skill.name }}
                        </div>

                        <div class="div-skill-op" v-for="(skillOp) in skill.children" :key="skillOp.id"
                            :parent-skill-id="skill.id" :parent-task-id="skillOp.task_id">
                            <div class="div-task-input">
                                <input :skill-op-id="skillOp.id" @click="CheckSkillsOp(skill.id)" type="checkbox"
                                    v-model="skillOp.checked">
                                {{ skillOp.name }}
                            </div>

                            <div class="div-skill-op-ddl">
                                <select class="sel-skill-op-proeficiency ddl-alt-padding " v-model="skillOp.selected_proeficiency">
                                    <option v-show="!prof.hide" v-for="prof in skillOp.proficiency_ddl" :key="prof.id" :value="prof.id">
                                        {{ prof.designation }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <label for="" class="group-name lbl-users">Pessoas</label>
        <div id="div-users-result" class="scrollbar">
            <div class="div-user-header" v-if="users.length > 0">
                <span class="user-name">Nome</span>
                <span>Proficiência</span>
                <span>Validação</span>
            </div>
            <div class="div-user" :class="{'bg-alt-line': i % 2 == 0}" v-for="(user, i) in users" :key="user.user_id" :tooltip-data="JSON.stringify(user.skill_ops)">
                <span class="user-name">{{ user.name }}</span>
                <span>{{ user.proeficiency_average }}</span>
                <span>{{ `${user.validation_percent ?? '0.00'}%` }}</span>
            </div>
        </div>

        <div v-if="users.length > 0">
            <div class="save-list-container">
                <input type="text" placeholder="Defina o nome da lista" v-model="listName">
                <button @click="SaveList()">GUARDAR LISTA</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
#div-activities,
#div-skills {
    padding: 3px;
    font-weight: bold;
}

#div-criterias {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
}

#div-criterias>div {
    border: 1px solid;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.select-criteria-type {
    width: 125px;
}

.div-search-criterias {
    display: flex;
}

.div-search-criterias,
.div-search-criterias input {
    flex: 2;
    width: 30rem;
}

.div-search-criterias button {
    margin-left: 5px;
    width: 125px;
}

#div-search-criterias-container {
    display: flex;
    margin-bottom: 1%;
    align-items: center;
    justify-content: space-between;
}

.div-result-criterias {
    width: 35%;
}

.div-criterias-header {
    color: #120d19;
    background-color: #afcdff30;
    opacity: .5;
    padding: 3px 8px;
    font-size: .9em;
    border-radius: 20px;
    margin-bottom: 10px;
}

#div-result-criterias,
#div-expanded-criterias,
#div-users-result  {
    border: 1px solid;
    padding: 10px;
    overflow-y: scroll;
    height: 300px;
}

.div-expanded-criterias,
.div-expanded-criterias-header {
    flex-grow: 1;
}

#div-criteria-result-container,
#div-users-result {
    margin-bottom: 2%;
}

#div-criteria-result-container,
#div-criteria-result-container-header {
    display: flex;
    align-items: center;
    gap: 2%;
}

#div-search-users {
    display: flex;
}

#div-left-criteria {
    display: flex;
    width: 35%;
    align-items: center;
}

.div-activity,
.div-skill {
    border: 1px solid;
    border-radius: 25px;
}

.div-task, .div-skill-op {
    justify-content: space-between;
    padding: 3px 5px !important;
}

.div-skill,
.div-activity,
.div-criteria,
.div-task,
.div-skill-op,
.div-user { 
    padding: 5px;
    display: flex;
    align-items: center;
    border-radius: 25px;
}

.div-user-header {
    font-weight: bold;
}

.div-user,
.div-user-header {
    display: grid;
    gap: 1.5%;
    grid-template-columns: 30% 10% 10%;
    text-align: center;
}

.div-task-input {
    align-self: center;
    display: flex;
}

.div-criteria,
.div-task-input input,
.div-skill-op input,
.div-activity input,
.div-skill input {
    cursor: pointer;
}

.div-criteria:hover {
    transform: scale(1.01);
}

.user-name {
    text-align: start !important;
}

.group-name {
    font-weight: bold;
    align-self: flex-end;
}

/* .criterias, */
.div-expanded-criterias-header button {
    margin-right: 5px;
    width: 80px;
}

.criterias-title {
    display: flex;
}

.criterias {
    align-self: flex-end;
}

.clear:hover {
    color: var(--default-app-hover-color);
}

.div-expanded-criterias-header,
.lbl-users {
    display: flex;
    justify-content: space-between;
}

.div-task-assignment-level,
.div-skill-op-ddl {
    width: 177px;
}

.sel-skill-op-proeficiency {
    width: 100%;
}

</style>
