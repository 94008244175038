<script setup>

import { KNOLIA } from "@/knolia"
import { ref, defineEmits, onMounted } from "vue"
import { Gems } from "@/gems"
import { MESSAGES } from "../messages"

const emits = defineEmits(['force-reload', 'close-profile-modal'])
const userData = ref({})
const coreData = ref({})
const selTeams = ref(null)
const photoUser = ref(null)
const inputUserPhoto = ref(null)
const defaultImage = ref(require('@/images/default.png'))

function DisassociateTeam(team) {
    userData.value.assigned_teams.forEach((assignedTeam, i) => {
        if (assignedTeam.id == team.id) {
            userData.value.assigned_teams.splice(i, 1)
        }
    })
}

function AssociateTeam() {
    let selectedTeam, hasDuplicated = false

    coreData.value.teams.forEach(team => {
        if (team.id == selTeams.value.value)
            selectedTeam = team
    })

    userData.value.assigned_teams.forEach((team, i) => {
        if (team.id == selectedTeam.id)
            hasDuplicated = true

        if (!team.id)
            userData.value.assigned_teams.splice(i, 1)
    })

    if (!hasDuplicated)
        userData.value.assigned_teams.push(selectedTeam)
}

function SelectUserPhoto() {
    const reader = new FileReader()

    reader.onload = () => userData.value.src = reader.result
    reader.readAsDataURL(inputUserPhoto.value.files[0] ?? new Blob())
}

async function EditUser() {
    const formData = new FormData()

    if (inputUserPhoto.value.files[0])
        formData.append('photo', inputUserPhoto.value.files[0])

    const bodyData = Object.assign(userData.value, {
        currentPass: document.querySelector(`#div-user-password > input`),
        newPass: document.querySelector(`#div-user-new-password > input`),
        confirmPass: document.querySelector(`#div-user-confirm-password > input`)
    })

    formData.append('body', JSON.stringify(bodyData))

    const response = await KNOLIA.CallBackEnd('post', 'edit-user', formData)
    if (!response.data.error)
        Gems.Telegram(MESSAGES.SUCCESS.SAVED, 'tele-success')

    emits('force-reload')
}

onMounted(async () => {
    const response = await KNOLIA.CallBackEnd('get', `get-user&user_id=${KNOLIA.GetRequestedUserId()}`)
    userData.value = response.data.user
    coreData.value = response.data.core
    
    const newTeam = document.getElementById('add-team')
        
    if(newTeam)
        Gems.Tooltip(newTeam, 'Adicionar colaborador(a) a esta equipa', 'tooltip')
})
</script>

<template>
    <div id="div-container-modal" class="modal">
        <div id="div-user-content" class="modal-content">
            <div class="close-modal">
                <i class="fas fa-times" @click="emits('close-profile-modal')"></i>
            </div>
            <!-- <h1>Perfil</h1> -->

            <div id="div-user-data">
                <div class="div-user-input" id="div-user-img" @click="inputUserPhoto.click()">
                    <input ref="inputUserPhoto" class="file-user-photo" type="file" @change="SelectUserPhoto">
                    <img ref="photoUser" class="div-logo" :src="userData.src" @error="$event.target.src = defaultImage">
                </div>

                <div class="div-group">
                    <div class="div-user-input div-little" id="div-user-internal">
                        <label for="">Nº Interno</label>
                        <input type="text" v-model="userData.user_internal">
                    </div>
                    <div class="div-user-input div-larger" id="div-user-name">
                        <label for="">Nome Completo</label>
                        <input type="text" v-model="userData.complete_name">
                    </div>
                </div>


                <div class="div-group">
                    <div class="div-user-input div-little" id="div-user-internal">
                        <label for="">Género</label>
                        <select class="cursor-pointer" v-model="userData.gender_id">
                            <option :value="gender.id" v-for="gender in coreData.genders" :key="gender.id">
                                {{ gender.designation }}
                            </option>
                        </select>
                    </div>
                    <div class="div-user-input div-larger" id="div-user-name">
                        <label for="">Nome Profissional</label>
                        <input type="text" v-model="userData.professional_name">
                    </div>
                </div>

                <div class="div-group">
                    <div class="div-user-input flex-1" id="div-user-job">
                        <label for="">Função</label>

                        <select class="cursor-pointer" v-model="userData.job_id">
                            <option :value="job.id" v-for="job in coreData.jobs" :key="job.id">
                                {{ job.designation }}
                            </option>
                        </select>
                    </div>

                    <div class="div-user-input flex-1" id="div-user-category">
                        <label for="">Categoria</label>

                        <select class="cursor-pointer" v-model="userData.category_id">
                            <option :value="category.id" v-for="category in coreData.categories" :key="category.id">
                                {{ category.designation }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="div-group">
                    <div class="div-user-input flex-1" id="div-user-email">
                        <label for="">E-mail</label>
                        <input type="text" v-model="userData.user_email">
                    </div>
                    <div class="div-user-input flex-1" id="div-user-username">
                        <label for="">Username</label>
                        <input type="text" v-model="userData.username">
                    </div>
                </div>

                <div class="div-group">
                    <div class="left flex-1">
                        <div class="div-user-input" id="div-user-teams">
                            <label for="">Equipas</label>
    
                            <div id="div-teams">
    
                                <select ref="selTeams" class="ddl-teams cursor-pointer">
                                    <option :value="team.id" v-for="team in coreData.teams" :key="team.id">
                                        {{ team.designation }}
                                    </option>
                                </select>
    
                                <i id="add-team" class="fas fa-circle-plus cursor-pointer" @click="AssociateTeam()"></i>
                            </div>
    
                            <div id="div-assigned-teams">
                                <div class="div-assigned-team" v-for="team in userData.assigned_teams" :key="team.id">
                                    {{ team.designation }}
                                    <i v-show="team.id" class="fas fa-minus close" @click="DisassociateTeam(team)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="right flex-1">
                        <div class="group div-user-input" id="div-user-password">
                            <label for="">Password atual</label>
                            <input type="text" value="">
                        </div>
                        <div class="div-user-input" id="div-user-new-password">
                            <label for="">Nova Password</label>
                            <input type="text">
                        </div>
                        <div class="div-user-input" id="div-user-confirm-password">
                            <label for="">Confirmar Nova Password</label>
                            <input type="text" value="">
                        </div>
                    </div>
                </div>

                <div id="div-save-user-data" class="div-group">
                    <div class="flex-1 div-toggle-container">
                        <div class="div-toggle">
                            <label for="">Ativo</label>
                            <i @click="userData.active = !userData.active" class="fas cursor-pointer"
                            :class="{ 'fa-toggle-on': userData.active, 'fa-toggle-off': !userData.active }"></i>
                        </div>
                        
                        <div class="div-toggle">
                            <label for="">Administrador</label>
                            <i @click="userData.admin = !userData.admin" class="fas cursor-pointer"
                            :class="{ 'fa-toggle-on': userData.admin, 'fa-toggle-off': !userData.admin }"></i>
                        </div>
                    </div>
                    <div class="flex-1 div-save">
                        <button class="btn-save" @click="EditUser()">GRAVAR</button>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<style scoped>

.modal-content {
    position: absolute;
    top: 2%;
    left: 25%;
    transform: translate(0, 0);
    backdrop-filter: blur(2px);
}

#div-save-user-data {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 1.2em;
}

#div-user-img {
    margin: 0 auto;
    cursor: pointer;
    width: 150px;
}

.div-logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.div-group {
    padding: 10px 20px;
    display: flex;
    gap: 10px;
}

.div-user-input>* {
    width: -webkit-fill-available;
}

label {
    font-weight: bold;
    margin-right: 5px;
    display: block;
}


.div-assigned-team {
    border-radius: var(--default-app-radius);
    border: 1px solid;
    padding: 10px 15px;
    margin: 5px 5px 5px 0px;
}

#div-assigned-teams {
    display: flex;
    margin-top: 5px;
    flex-wrap: wrap;
}

#div-teams {
    display: flex;
    align-items: center;
}

#div-teams>.fa-plus {
    margin-left: 5px;
}

.file-user-photo {
    cursor: pointer;
    display: none;
}

.div-toggle {
    text-align: center;
}

.div-toggle i {
    font-size: 1.5em;
}

.div-little {
    width: 20%
}

.div-larger {
    width: 80%;
}

.flex-1 {
    flex: 1;
}

.flex-5 {
    flex: 5;
}

.right div:not(:first-child) {
    padding: 10px 0;
}

.ddl-teams {
    width: 95%;
}

.div-toggle-container {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.div-save {
    text-align: end;
}

.btn-save {
    width: 150px;
}

.fa-circle-plus {
    font-size: 1.3em;
    margin-left: 5px;
}

.close-modal {
    display: flex;
    justify-content: end;
    padding: 10px 10px 0px 0px;
}

.close-modal i {
    font-size: 1.5em;
    cursor: pointer;
}

.modal-content {
    border: 1px solid;
}
</style>