<script setup>

import { KNOLIA } from "@/knolia";
import { onUpdated, defineProps, defineEmits } from "vue";
import { Gems } from "../gems";

const props = defineProps({
    activities: {
        type: Array,
        default: () => []
    },
    currentJob: {
        type: Object,
        default: () => {}
    },
    draggable: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['remove-task', 'get-job-activities'])

async function SelectTaskAssignment(tasks, assignmentLevel) {
    tasks.forEach(task => task.selected_assignment = assignmentLevel)

    if(!props.draggable)
        KNOLIA.CallBackEnd('post', `set-operations&get=change-assignment&job_id=${props.currentJob.id}`, {
            tasks
        }).then(() => {
            props.activities.forEach((activity, index) => {
                let removedTask = 0
                activity.children.forEach(task => {
                    removedTask += task.selected_assignment == 0 ? 1 : 0
                    // emit('get-job-activities') 
                })
                if (removedTask == activity.children.length)
                    emit('remove-task', index)
            })
        })
}

function RemoveTaskFromJob(activityId) {
    KNOLIA.CallBackEnd('post', `set-operations&get=delete-task-of-job`, {
        activityId
    })

    let indexToDelete

    props.activities.forEach((activity, index) => {
        if (activityId == activity.id)
            indexToDelete = index
    })

    emit('remove-task', indexToDelete)
}

function HoldTasks(event, tasks) {
    tasks = tasks.map(task => {
        return {id: task.id, selected_assignment: task.selected_assignment}
    })

    event.dataTransfer.setData('tasks', JSON.stringify(tasks))
}

onUpdated(() => {
    props.activities.forEach((elem, i) => {
        const removeIcon = document.getElementById(`remove-task-${i}`)

        if(removeIcon)
            Gems.Tooltip(removeIcon, 'Remover atividade da função', 'tooltip')
    })
})

</script>

<template>
    <div class="div-activity-container" v-for="(activity, i) in activities" :key="activity.id">
        <div v-if="!activity.unavailable" :draggable="draggable" @dragstart="HoldTasks($event, activity.children)" class="div-row bg-alt-line div-activity " :class="!draggable ? 'div-job-row' : ''" :id="draggable ? `activity${activity.id}` : ''">
            <div>
                <i class="fas show-children cursor-pointer" :class="{'fa-chevron-down': !activity.showChildren , 'fa-chevron-up': activity.showChildren}" 
                @click="activity.showChildren = !activity.showChildren"></i>
                <span>{{ activity.designation }}</span>
            </div>
            
            <div>
                <select class="cursor-pointer ddl-alt-padding" v-model="activity.selected_assignment"  @change="SelectTaskAssignment(activity.children, $event.target.value)">
                    <option v-for="assignment in activity.assignment_levels" :key="assignment.level" :value="assignment.level">
                        {{ assignment.designation }}
                    </option>
                </select>
                <i v-if="!draggable" @click="RemoveTaskFromJob(activity.id)" class="fa fa-times-circle remove-task" :id="`remove-task-${i}`"></i>
            </div>

        </div>

        <div class="div-tasks" v-show="activity.showChildren">
            <div class="div-task-container div-row" 
                v-for="task in activity.children" :key="task.id">
                <div class="div-task line-clamp line-clamp-1" @mouseover="Gems.TooltipIfNeeded($event.target)">{{ task.designation }}</div>

                <select class="cursor-pointer ddl-alt-padding" :class="!draggable ? 'job-task' : ''" v-model="task.selected_assignment" @change="SelectTaskAssignment([task], $event.target.value)">
                    <option :value="0"> Não atribuida </option>
                    <option v-for="assignment in task.assignment_levels" :key="assignment.level" :value="assignment.level">
                        {{ assignment.designation }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<style scoped>

.div-task-container {
    margin-top: 5px;
}

.div-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 5px 15px;
    cursor: pointer;
    align-items: center;
    margin-bottom: 3px;
}

.div-job-row {
    cursor: default !important;
}

.div-activity:hover,
.div-activity:hover select {
    color: var(--default-app-hover-color) !important;
    border-color: var(--default-app-hover-color);
}

.div-activity,
.div-task-container {
    height: 25px;
    padding-left: 15px;
    padding-right: 15px;
}

.div-row:not(:first-child)  {
    margin-top: 8px;
}

.remove-task {
    cursor: pointer;
    margin-left: 15px;
}

.show-children {
    margin-right: 5px;
}

.unavailable {
    pointer-events: none;
    opacity: .5;
}

.job-task {
    margin-right: 29px;
}
</style>