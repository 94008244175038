<script setup>
import { onMounted, onUpdated } from "vue"
import { ref } from "@vue/reactivity"
import { KNOLIA } from "@/knolia"
import { Gems } from "@/gems"
import { defineEmits } from "vue"
import { MESSAGES } from "../messages"
import Profile from "../components/Profile.vue"
import { useRouter } from "vue-router"

const defaultImage = ref(require('@/images/default.png'))

defineEmits(['force-reload'])

const router = useRouter();
const arrUsers = ref([])
const abortController = ref(null)
const txtUser = ref('')
const actualHeadship = ref(null)
const sitemapArr = ref([{name: "CEO's", id: null}])
const arrUsersActive = ref([])
const showInactives = ref(false)
const reload = ref(false)
let canShowProfileModal = ref(false)

function GoToSubordinates(user) {
    if(txtUser.value != '')
        sitemapArr.value = [{name: "CEO's", id: null}]

    sitemapArr.value.push(user)
    GetHierarchy(user.id)
}

function GetSitemapSubordinates(userId, index) {
    const soma = parseInt(index, 10);
    const max = sitemapArr.value.length - soma;
    sitemapArr.value.splice(soma, max);
    
    GetHierarchy(userId)
}

async function GetHierarchy(user_id = null) {
    if(user_id)
        actualHeadship.value = user_id

    const res = await KNOLIA.CallBackEnd("get", "get-hierarchy", {
        params: {
            user_id
        },
    })

    if(res.error) {
        Gems.Telegram(MESSAGES.ERROR.DEFAULT, 'tele-not-allowed')
        return
    }

    CreateUsersArr(res.data)
}

async function SearchUsers() {
    if(txtUser.value == "") {
        GetHierarchy(actualHeadship.value)
        return
    }

    if (abortController.value != null) 
        abortController.value.abort();

    abortController.value = new AbortController();

    const res = await KNOLIA.CallBackEnd("get", "filter-hierarchy", {
        params: {
          user_id: actualHeadship.value,
          search_text: txtUser.value,
          signal: abortController.value.signal,
        },
    })

    CreateUsersArr(res.data)
}

function CreateUsersArr(usersArr) {
    arrUsersActive.value = []

    usersArr.forEach(user => {
        if (user.active == 1)
          arrUsersActive.value.push(user)
    })

    arrUsers.value = usersArr   
}

function ShowInactives() {
    reload.value = !reload.value;
}

function ToProfile(user) {
    KNOLIA.SetRequestedUserId(user.id)
    canShowProfileModal.value = true
}

function ToSkillCard(user) {
    KNOLIA.SetRequestedUserId(user.id)
    router.push('/skill-card')
}

onMounted(() => {
    GetHierarchy()
})

onUpdated(() => {
    arrUsers.value.forEach(user => {
        const hierarchyIcon = document.getElementById(`hierarchy${user.id}`)
        const skillCardIcon = document.getElementById(`skill-card${user.id}`)

        if(hierarchyIcon)
            Gems.Tooltip(hierarchyIcon, 'Hierarquia', 'tooltip')
        
        if(skillCardIcon)
        Gems.Tooltip(skillCardIcon, 'Cédula de competências', 'tooltip')
        
    })
})
</script>

<template>
    <div class="page-container" id="page-container">
        <div class="div-search-users">
            <input type="text" class="input-user" placeholder="Pesquisar colaborador..." v-model="txtUser" @keyup="SearchUsers()">
            <div class="div-inactives-checkbox">
                <input class="chk cursor-pointer" type="checkbox" v-model="showInactives" @click="ShowInactives">
                <label>MOSTRAR INATIVOS</label>
            </div>
        </div>
        
        <div class="sitemap" v-if="sitemapArr.length > 1">
            <span class="name-container" v-for="(sitemap, i) in sitemapArr" :key="i">
                <span class="headship" @click="GetSitemapSubordinates(sitemap.id, i)">
                    {{ sitemap.name }}
                </span>
                <span v-if="++i < sitemapArr.length">
                    <i class="fas fa-chevron-right sitemap-icon" aria-hidden="true" ></i>
                </span>
            </span>
        </div>
        
        <div class="users-list">
            <div class="row" v-for="(user, index) in showInactives ? arrUsers : arrUsersActive" :key="user.id" :class="{'bg-alt-line': index % 2 == 0}">
                <img ref="div-logo" class="div-logo" :src="user.src" @error="$event.target.src = defaultImage">
                <span class="user-name" @click="ToProfile(user)">{{ user.name }}</span>
                <span>{{ user.job }}</span>
                <span>{{ user.category }}</span>
                <span class="icons">
                    <i class="far fa-address-card" :id="`skill-card${user.id}`" @click="ToSkillCard(user)"></i>
                    <i :class="{inactive: showInactives ? !user.has_subordinates: !user.has_subordinates_active}" class="fas fa-sitemap" :id="`hierarchy${user.id}`" @click="GoToSubordinates(user)"></i>
                </span>
            </div>
        </div>
    </div>

    <Profile v-if="canShowProfileModal" @close-profile-modal="canShowProfileModal = false"/>
</template>

<style scoped>

.div-search-users {
    display: flex;
    margin-bottom: 1%;
}

.div-inactives-checkbox {
    display: flex;
    align-self: center;
    margin-left: 15px;
}

.div-inactives-checkbox label {
    margin-right: 10px;
}

.chk {
    transform: scale(1.5);
    margin-right: 15px;
}

.input-user {
    flex: 1;
    font-style: italic;
}

.sitemap {
  margin-bottom: 1%;
}

.sitemap-icon {
    margin: 0 5px;
}

.sitemap .headship:hover {
  color: var(--default-app-hover-color);
  cursor: pointer;
}

.row {
    padding: 5px;
    margin-bottom: 3px;
    display: grid;
    grid-template-columns: 3% 30% 29% 29% 9%;
    align-items: center;
    border-radius: 5px;
}

.user-name {
    font-weight: bold;
    cursor: pointer;
    margin-left: 5px;
}

.icons {
    display: flex;
    justify-content: space-evenly;
}

.icons i {
    cursor: pointer;
    font-size: 1.3em;
}

.inactive {
    pointer-events: none;
    cursor: default !important;
    opacity: .2;
}

.div-logo {
  height: 30px;
  width: 31px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 10px 1px rgb(0, 0, 0, 0.1);
  margin: 0 auto;
  object-fit: cover;
}
</style>
