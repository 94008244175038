<!-- eslint-disable no-undef -->
<script setup>
import { onMounted, defineEmits, ref, onUpdated } from "vue";
import { useStore } from "vuex";
import { KNOLIA } from "../knolia";
import { Gems } from "../gems";
import TreeType from "../enums/TreeType";
import { MESSAGES } from "../messages";

const emit = defineEmits(["filter-children", "show-parent"])
const props = defineProps([
  "element",
  "parent",
  "meta_id",
  "columnDepth",
  "is_meta",
  "depth",
  "index"
]);
const store = useStore()
let inputNameRef = ref()
let showOptions = ref(false)

function ChangeMetaOrElement() {
  Object.assign(props.element, {selected: !props.element.selected})
  
  if (props.is_meta == true) 
    store.commit("setSelectedMeta", props.element.selected ? props.element : [])
  else
    store.dispatch("FilterChildren", [props.element, props.element.selected, props.meta_id])
}

async function Drop(event, element) {
  const checkDepth = element.totalDepth >= 2 ? element.totalDepth - 2 : 1;
  const newItem = JSON.parse(event.dataTransfer.getData("element"));
  newItem["selected"] = false;
  newItem["showSelf"] = element.selected;

  if (newItem.fromSkillList) {
    newItem["parent_id"] = element.id;
    newItem["depth"] = parseInt(element.depth, 10) + 1;

    if (element.depth != checkDepth) {
      Gems.Telegram(MESSAGES.ERROR.NOT_ALLOWED, "tele-not-allowed")
      return;
    }

    if (element.depth == checkDepth) {
      await KNOLIA.CallBackEnd("post", "control-tree-element", {
        element: newItem,
        parent: element,
        treeType: TreeType.SKILLS,
        update: "skills",
      }).then(() => {
        store.dispatch("UpdateSkillsTree", [newItem]);
        Gems.Telegram(MESSAGES.SUCCESS.INSERTED, "tele-success");
      });
    }
  } else {
    if (element.totalDepth - 2 <= 0) {
      Gems.Telegram(MESSAGES.ERROR.NOT_ALLOWED,"tele-not-allowed")
      return
    }

    if (newItem.depth >= parseInt(element.depth, 10) + 1 && newItem.id != element.id) {
      await KNOLIA.CallBackEnd("post", "update-tree-position", {
        element_id: newItem.id,
        element: newItem,
        target: element,
        to: "element",
        treeType: TreeType.TASKS,
      }).then(() => {
        store.dispatch("UpdateSkillsTree", [newItem]);
        Gems.Telegram(MESSAGES.SUCCESS.INSERTED, "tele-success");
      });
    } else {
      Gems.Telegram(MESSAGES.ERROR.NOT_ALLOWED, "tele-not-allowed")
      return
    }
  }
}

function OptionsHandler(status) {
  if (!status) {
    inputNameRef.value.setAttribute("readonly", !status);
    inputNameRef.value.classList.add("pointer");
  } else {
    inputNameRef.value.removeAttribute("readonly");
    inputNameRef.value.classList.remove("pointer");
  }
  showOptions.value = status;
}

function ChangeInput(element) {
  if (element.is_skill || element.is_skill_op || isKnowledge(element))
    return

  if(element.new)
    element.designation = ''
  
  OptionsHandler(true)
}

function isKnowledge(elem) {
  return elem.meta_comp && elem.elem_id == 1
}

function ElementConfirm() {
  OptionsHandler(false)

  store.dispatch("UpdateElementName", {
    name: inputNameRef.value.value,
    update: props.is_meta ? "meta" : "element",
    treeType: TreeType.SKILLS,
    element: props.element
  })
}

function ElementCancel() {
  if (props.element.new) {
    store.commit("removeElementAndChilds", props.element)
  } else {
    OptionsHandler(false)
    inputNameRef.value = props.element.description
  }
}

function Remove(element) {
  Gems.Telegram(MESSAGES.QUESTIONS.REMOVE_FROM_TREE, "tele-info", "", "tele-cancel").then(
    () => {
      if (element.is_skill) {
        element.relational_id = element.id
        store.dispatch("DissociateElement", [element, "skill"])
      } else {
        store.dispatch("DissociateElement", [element, "element"])

        if(props.is_meta && props.meta_id == element.meta_id)
          store.commit('setSelectedMeta', [])
      }
    },
    () => {}
  );
}

async function CreateElem() {
  if(!props.element.selected)
    Object.assign(props.element, {selected: !props.element.selected})

  if (props.is_meta == true) 
    if(props.element.levels.length == 2)
      await store.dispatch('NewColumn', {meta_id: props.element.meta_id, depth: parseInt(props.element.depth, 10) + 1, elements: [], treeType: TreeType.SKILLS})
  
  KNOLIA.NewElem(store, props.element, 'insertChild')

  if (props.is_meta == true) 
    store.commit("setSelectedMeta", props.element)
  else
    store.dispatch("FilterChildren", [props.element, true, props.meta_id])
}

onMounted(() => {
  Object.assign(props.element, { selected: KNOLIA.ToBoolean(props.element.selected) })
  Object.assign(props.element, { is_skill_op: KNOLIA.ToBoolean(props.element.is_skill_op) })

  emit("show-parent")
  store.commit('setElementsTooltips', props.element)

  inputNameRef.value.value = props.element.designation
})

onUpdated(() => {
  store.commit('setElementsTooltips', props.element)
})

</script>

<template>
  <div class="div-depth-element">

    <span v-if="props.element.parent_designation" class="parent-name">{{ props.element.parent_designation }}</span>
    
    <div class="element-row default-radius" :class="{'opened-row': element.selected}">

      <i class="fas cursor-pointer show-childs" :class="{'fa-plus': !element.selected, 'fa-minus': element.selected}" :id="`checkbox${props.element.id}`"
          v-if="(!props.element.is_skill_op && props.element.has_children > 0) || props.is_meta"
          @click="ChangeMetaOrElement"
      ></i>
      
      <div v-else-if="!props.element.is_skill_op" class="checkbox-div"></div>

      <input readonly="true" ref="inputNameRef" class="input"
        :draggable="KNOLIA.IsDraggable(props.element, props.columnDepth, showOptions)"
        :value="props.element.designation"
        @dragstart="KNOLIA.Drag($event, props.element)"
        @dragover.prevent
        @drop="Drop($event, props.element)"
        @dblclick="ChangeInput(element)"
      />

      <div class="div-menu-edit" v-show="showOptions || props.element.new">
        <i class="fa fa-check confirm" :id="`confirm-elem-name${element.id}`" @click="ElementConfirm"></i>
        <i class="fa fa-times cancel" :id="`cancel-elem-name${element.id}`" @click="ElementCancel"></i>
      </div>

      <div class="element-std-icons">
        <i class="fa fa-plus-circle i-add-elem" :id="`new-elem${props.element.id}`"
          v-show="!showOptions && !props.element.new && !isKnowledge(element) && (props.element.depth < props.element.totalDepth - 2 || props.is_meta)"
          @click="CreateElem"></i>

        <i class="fa fa-trash remove-icon" :id="`delete${props.element.id}`"
          v-if="!props.element.is_skill_op && !isKnowledge(element)"
          v-show="!showOptions && !props.element.new" @click="Remove(props.element)"></i>
      </div>

    </div>
  </div>
</template>

<style scoped>
  @import './assets/styles/trees-style.css';
</style>
