import Vuex from "vuex";
import authentication from "./modules/authentication";
import trees from "./modules/trees";
import user from "./modules/user";

const store = new Vuex.Store({
  modules: {
    authentication,
    trees,
    user,
  },
});

export default store;
