<script setup>
import { useRouter } from "vue-router"
import { onMounted } from "vue"
import { useStore } from "vuex"

const store = useStore()
const router = useRouter()

onMounted(() => {
  if(!store.getters.User.is_headship)
    router.push('/home')  
})
</script>

<template>
  <div class="div-container">
    <div class="buttons">
        <button class="btn-white" @click="router.push('user-activity-control')">GERIR ATIVIDADES</button>
        <button class="btn-white" @click="router.push('search-users')" v-if="store.getters.User.permissions.can_search_users">ENCONTRAR PESSOAS</button>
    </div>
  </div>
</template>

<style scoped>

    .div-container {
        margin: 10% auto;
        flex-direction: column;
        display: flex;
        width: 900px;
    }

    .buttons button {
        width: 300px;
        height: 50px;
        margin: 10px;
    }

    .buttons {
      align-self: center;
    }

</style>
