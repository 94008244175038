<script setup>
import { onMounted, onUpdated } from "vue";
import { useStore } from "vuex";
import { Gems } from "@/gems";

const store = useStore()

function Drag(event, element) {
  element.fromElementList = true;
  event.dataTransfer.setData("element", JSON.stringify(element));
}

function SetTooltips() {
  for (let element of store.getters.ActivitiesArr) {
    const sitemap = document.getElementById(`element${element.id}`)
    if(sitemap)
      Gems.Tooltip(sitemap, element.sitemap, "tooltip tooltip-left")


    const children = document.getElementById(`show-children${element.id}`)
    if(children)
      Gems.Tooltip(children, "Ver lista de tarefas", "tooltip")

    const deleteIcon = document.getElementById(`delete${element.id}`)
    if(deleteIcon && element.included)
      Gems.Tooltip(deleteIcon, "Remover da árvore", "tooltip")
  }
}

onMounted(() => {
  SetTooltips()
});

onUpdated(() => {
  SetTooltips()
});
</script>

<template>
  <section class="element-list-section ">
    <div class="element-container">
      <div class="element-title">
        <span>Atividades</span>
      </div>
      
      <div v-for="(element, i) in store.getters.ActivitiesArr" :key="i" :draggable="true" @dragstart="Drag($event, element)">
        <div class="element-line default-radius" :style="element.visible">

          <div class="div-icon cursor-pointer">
            <i class="fa fa-share fa-flip-horizontal" :id="`delete${element.id}`"
              v-show="element.included" @click="store.dispatch('DissociateTasksElement', [element, 'tasks'])"></i>
          </div>

          <div class="element" :id="`element${element.id}`">
            <span class="line-clamp line-clamp-1">{{ element.designation }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

.element-list-section {
  text-align: center;
  max-width: max-content;
  min-width: 500px;
}

.element-title {
  font-weight: 700;
}

.element-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
  cursor: move;
  background-color: #003e7825;
}

.element-line:hover {
  color: var(--default-app-hover-color);
}

.div-icon {
  font-size: 0.8em;
}

.element {
  text-align: center;
  padding: 5px;
  width: 95%;
  margin-left: -5px;
}

.element-children-icon {
  font-size: 0.8em;
  margin-left: 15px;
  cursor: pointer;
}

</style>
