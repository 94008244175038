/* eslint-disable prettier/prettier */
import { Gems } from "@/gems"
import { KNOLIA } from "@/knolia"
import TreeType from "@/enums/TreeType"
import { MESSAGES } from "../../messages";


const defaultTree = () => {
    return {
        tree: [],
        activitiesArr: [],
        ACTIVITIES: 1,
        selectedMeta: [],
        skillsArr: [],
        SKILLS: 2,
    };
};

const state = {
    tree: [],
    activitiesArr: [],
    ACTIVITIES: 1,
    selectedMeta: [],
    skillsArr: [],
    SKILLS: 2,
};

const getters = {
    Tree: (state) => state.tree,
    ActivitiesArr: (state) => state.activitiesArr,
    ACTIVITIES: (state) => state.ACTIVITIES,
    SelectedMeta: (state) => state.selectedMeta,
    SkillsArr: (state) => state.skillsArr,
    SKILLS: (state) => state.SKILLS,
};

const actions = {

    async UpdateTasksTree({ dispatch }, arr) {
        dispatch("GetTasksDepth").then(() => {

            dispatch("UpdateTreeElements", arr);
            dispatch("GetActivities");
            dispatch("GetSkills", { treeType: "tasks" });
        });
    },

    async UpdateSkillsTree({ dispatch }, arr) {
        dispatch("GetDepth").then(() => {
            dispatch("UpdateTreeElements", arr);

            dispatch("GetSkills");
        });
    },

    async GetSkills({ state }, params) {
        await KNOLIA.CallBackEnd("get", "get-skills-list", { params }).then((res) => {
            state.skillsArr = KNOLIA.CovertNumToBool(res.data, "included");
            state.skillsFiltered = state.skillsArr;
            document.getElementById("reset-skills")?.click();
        });
    },

    UpdateTreeElements({ state, commit }, droppedElement) {
        const treeIndex = state.tree.findIndex((tree) => tree.id == state.selectedMeta.id)

        if (state.tree[treeIndex] == undefined)
            return

        state.tree[treeIndex].selected = true

        for (let [levelIndex, level] of state.tree[treeIndex].levels.entries()) {
            const oldLevel = state.selectedMeta.levels[levelIndex]

            if (oldLevel && oldLevel.id == level.id) {
                for (let [, elem] of level.elements.entries()) {
                    oldLevel.elements.forEach(selectedElem => {
                        if (selectedElem.id == elem.id) {
                            elem.selected = selectedElem.selected
                            elem.showSelf = selectedElem.showSelf
                            if (elem.selected)
                                commit('showElement', [elem.id, parseInt(elem.depth, 10) + 1, elem.meta_id])
                        }
                    })

                    if (droppedElement) {
                        if (elem.id == droppedElement[0]['parent_id'])
                            elem.selected = true

                        if (elem.parent_id == droppedElement[0]["parent_id"])
                            elem.showSelf = true
                    }
                }

                oldLevel.elements.forEach(selectedElem => {
                    if (selectedElem.new)
                        level.elements.push(selectedElem)
                })
            }
        }
        commit("setSelectedMeta", state.tree[treeIndex])
    },

    async GetTasksDepth({ state }) {
        await KNOLIA.CallBackEnd("get", "get-tasks-tree").then((res) => {
            state.tree = res.data
        });
    },

    async GetDepth({ state }) {
        await KNOLIA.CallBackEnd("get", "get-skill-tree").then((res) => {
            state.tree = res.data
        });
    },

    async GetActivities({ state }) {
        await KNOLIA.CallBackEnd("get", "get-activities-list").then((res) => {
            state.activitiesArr = KNOLIA.CovertNumToBool(res.data, "included");
            state.activitiesFiltered = KNOLIA.CovertNumToBool(res.data, "included");
        });
    },

    async NewColumn({ dispatch }, params) {
        const res = await KNOLIA.CallBackEnd("post", "new-depth", { params })

        if (res.error) {
            Gems.Telegram(MESSAGES.ERROR.DEFAULT, 'tele-not-allowed')
            return
        }

        if (params.treeType == TreeType.SKILLS)
            await dispatch("GetDepth").then(() => {
                dispatch("UpdateTreeElements")
                dispatch("GetSkills")
            })
        else
            await dispatch("GetTasksDepth").then(() => {
                dispatch("UpdateTreeElements")
                dispatch("GetActivities");
                dispatch("GetSkills", { treeType: "tasks" });
            });

        // commit('updateNewColumn', params)
    },

    FilterChildren: ({ state, dispatch, commit }, arr) => {
        const checked = arr[1];
        const meta_id = arr[2];
        const elem = arr[0];
        const depth = parseInt(elem.depth, 10) + 1
        const treeIndex = state.tree.findIndex((tree) => tree.elem_id == meta_id)

        for (let [levelIndex, level] of state.tree[treeIndex].levels.entries()) {
            if (level.depth == depth) {
                if (checked)
                    commit('showElement', [elem.id, depth, meta_id])
                else
                    dispatch('HideRecursive', [state.tree[treeIndex].levels, [elem.id], levelIndex])
            }
        }
    },

    HideRecursive({ dispatch }, arr) {
        const newTarget = [];
        const level = arr[0];
        const itemArr = arr[1];
        const i = parseInt(arr[2], 10);

        if (level.length <= i)
            return

        if (itemArr.length > 0) {
            for (let item in itemArr) {
                for (let element of level[i]["elements"]) {
                    if (element) {
                        if (element.parent_id == itemArr[item]) {
                            newTarget.push(element.id);
                            element.showSelf = false;
                            element.selected = false;
                        }
                    }
                }
            }
        }
        dispatch("HideRecursive", [level, newTarget, i + 1]);
    },


    async DissociateTasksElement({ dispatch }, arr) {
        await KNOLIA.CallBackEnd("post", "dissociate-from-tree", {
            element: arr[0],
            type: arr[1],
            tree: "tasks"
        }).then((res) => {
            if (res.error) {
                Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed");
                return;
            }

            dispatch("UpdateTasksTree")
        });
    },

    async DissociateElement({ dispatch, commit }, arr) {
        await KNOLIA.CallBackEnd("post", "dissociate-from-tree", {
            element: arr[0],
            type: arr[1],
            tree: "skills"
        }).then((res) => {
            if (res.error) {
                Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed");
                return;
            }

            commit('removeElementAndChilds', arr[0])
            dispatch("UpdateSkillsTree")

            Gems.Telegram(MESSAGES.SUCCESS.REMOVED, "tele-success");
        });
    },

    async UpdateDepthName(_, params) {
        params.element.designation = params.name

        const response = await KNOLIA.CallBackEnd("post", "control-tree-element", params)

        if (response.error) {
            Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed")
            return
        }
    },

    async UpdateElementName({ state }, params) {
        params.element.designation = params.name.trim() == '' ? '[Novo elemento]' : params.name

        const res = await KNOLIA.CallBackEnd("post", "control-tree-element", params)
        if (res.error) {
            Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed")
            return
        }

        if (params.element.new) {
            params.element.id = res.data.new_id
            params.element.elem_id = res.data.elem_id

            state.selectedMeta.levels.forEach(level => {
                if (level.depth == params.element.depth - 1) {
                    const parent_ind = level.elements.findIndex(elem => { return elem.id == params.element.parent_id }, params.element)
                    level.elements[parent_ind].selected = true
                }
            })
        }

        state.tree.forEach(col => {
            if (col.elem_id == params.element.meta_id) {
                col.levels.forEach(level => {
                    if (level.depth == params.element.depth) {
                        level.elements.sort((a, b) => {
                            if (a.parent_id == params.element.parent_id)
                                return a.designation.localeCompare(b.designation)
                        })

                        let count = 0
                        level.elements.forEach(element => { count += element.parent_designation == params.element.parent_designation && element.parent_id == params.element.parent_id ? 1 : 0 })

                        if (count > 1) {
                            let ind = level.elements.findLastIndex(element => { return element.parent_designation == params.element.parent_designation && element.parent_id == params.element.parent_id }, params.element)
                            level.elements[ind].parent_designation = ''
                        }
                    }
                })
            }
        })

        params.element.new = false
    }
};

const mutations = {

    updateNewColumn: (state, params) => {
        const treeIndex = state.tree.findIndex((tree) => tree.elem_id == params.meta_id)

        state.tree[treeIndex].levels.forEach(level => {
            let ind

            if (params.meta)
                ind = params.meta.levels.findIndex(selLevel => selLevel.elem_id == level.elem_id)
            else
                ind = state.selectedMeta.levels.findIndex(selLevel => selLevel.elem_id == level.elem_id)

            const selectedMeta = state.tree[treeIndex].levels[ind]

            level.elements.forEach(element => {
                const elemInd = selectedMeta.elements.findIndex(selElem => selElem.elem_id == element.elem_id)

                if (elemInd != -1) {
                    element.showSelf = selectedMeta.elements[elemInd].showSelf
                    element.selected = selectedMeta.elements[elemInd].selected
                }
            })
        })

        state.selectedMeta = state.tree[treeIndex]
    },

    showElement: (state, arr) => {
        const treeIndex = state.tree.findIndex(tree => tree.elem_id == arr[2])

        const levelIndex = state.tree[treeIndex].levels.findIndex(level => level.depth == arr[1])

        if (levelIndex != -1)
            state.tree[treeIndex].levels[levelIndex].elements.forEach(element => {
                if (element.parent_id == arr[0])
                    element.showSelf = true
            })
    },

    setSelection: (state, arr) => {
        const treeIndex = state.tree.findIndex((tree) => tree.elem_id == arr[2])

        const levelIndex = state.selectedMeta.levels.findIndex((level) => level.depth == arr[0]['depth'])

        if (levelIndex >= 0)
            state.tree[treeIndex].levels[levelIndex].elements.forEach(element => {
                if (element.id == arr[0]["id"])
                    element.selected = arr[1]
            })
    },

    removeSkillFromList: (state, skill) => {
        for (let level of state.selectedMeta.levels) {
            for (let element in level.elements) {
                if (element.is_skill) {
                    if (element.elem_id == skill["skill_id"])
                        level.elements.splice(element, 1)
                }

            }
        }
    },

    insertChild: (state, elem) => {
        state.tree.forEach(col => {
            if (col.elem_id == elem.meta_id) {
                col.levels.forEach(level => {
                    if (level.depth == elem.depth - 1) {
                        const parent_ind = level.elements.findIndex(element => { return element.id == elem.parent_id }, elem)
                        level.elements[parent_ind].has_children++
                    }

                    if (level.depth == elem.depth) {
                        let ind = level.elements.findLastIndex(element => { return element.parent_id == elem.parent_id }, elem)

                        if (ind == -1) {
                            let parent_id_before
                            for (let i = 0; i < level.elements.length; i++) {
                                if (level.elements[i].parent_designation != '') {
                                    if (level.elements[i].parent_designation.localeCompare(elem.parent_designation) == 1
                                        && level.elements[i].parent_id.localeCompare(elem.parent_id) == 1)
                                        break

                                    parent_id_before = level.elements[i].parent_id
                                }
                            }

                            ind = level.elements.findLastIndex(element => { return element.parent_id == parent_id_before }, parent_id_before)
                        }

                        level.elements.splice(ind + 1, 0, elem)

                        level.elements.sort((a, b) => {
                            if (a.parent_id == elem.parent_id)
                                return a.designation.localeCompare(b.designation) && a.id < b.id
                        })

                        let count = 0
                        level.elements.forEach(element => {
                            count += element.parent_designation == elem.parent_designation && element.parent_id == elem.parent_id ? 1 : 0
                        })

                        if (count >= 1) {
                            ind = level.elements.findLastIndex(element => { return element.parent_designation == elem.parent_designation && element.parent_id == elem.parent_id }, elem)
                            level.elements[ind].parent_designation = ''
                        }
                    }
                })
            }
        })
    },

    removeElementAndChilds: (state, element) => {
        state.tree.forEach(col => {
            if (col.elem_id == element.meta_id) {
                col.levels.forEach(level => {
                    if (level.depth == element.depth - 1) {
                        const ind = level.elements.findIndex(elem => { return elem.id == element.parent_id }, element)
                        level.elements[ind].has_children--

                        if (level.elements[ind].has_children <= 0)
                            level.elements[ind].selected = !level.elements[ind].selected
                    }

                    if (level.depth == element.depth) {
                        const ind = level.elements.findIndex(elem => { return elem.id == element.id }, element)

                        if (level.elements[ind + 1] && level.elements[ind].parent_designation != '' && level.elements[ind + 1].parent_designation == '')
                            level.elements[ind + 1].parent_designation = level.elements[ind].parent_designation

                        level.elements.splice(ind, 1)
                    }

                    if (level.depth > element.depth) {
                        const ind = level.elements.findIndex(elem => { return elem.parent_id == element.id }, element)

                        if (ind != -1) {
                            if (level.elements[ind].parent_designation != '' && level.elements[ind + 1].parent_designation == '')
                                level.elements[ind + 1].parent_designation = level.elements[ind].parent_designation

                            level.elements.splice(ind, 1)
                        }
                    }
                })
            }
        })
    },

    changeElementName: async (state, { id, name }) => {
        for (let col in state.tree) {
            for (let level in state.tree[col]["levels"]) {
                for (let element in state.tree[col]["levels"][level]["elements"]) {
                    if (state.tree[col]["levels"][level]['elements'][element]["id"] == id) {
                        state.tree[col]["levels"][level]['elements'][element]["designation"] = name;
                    }
                }
            }
            if (state.tree[col]["id"] == state.selectedMeta['id']) {
                state.selectedMeta = state.tree[col];
            }
        }
    },

    showAllElements: (state, params) => {
        const treeIndex = state.tree.findIndex((tree) => tree.elem_id == params.meta_id)

        for (let level of state.tree[treeIndex].levels) {
            if (level.depth <= params.depth) {
                for (let element of level.elements) {
                    if (element) {
                        element.showSelf = true
                        if (level.depth < params.depth && element.has_children > 0)
                            element.selected = true
                    }
                }
            }
        }
    },

    filterLists: (state, props) => {
        const list = props.type == state.ACTIVITIES ? state.activitiesArr : state.skillsArr

        const arrFilteredElem = list.filter(
            (elem) =>
                elem.designation.trim().toUpperCase().search(props.searchText.trim().toUpperCase()) > -1
        )

        const arrFilteredChildren = list.filter((elem) => {
            const res = elem.children.filter(
                (skill_op) =>
                    skill_op.designation.trim().toUpperCase()
                        .search(props.searchText.trim().toUpperCase()) > -1
            )

            if (res.length > 0)
                return true
        })

        let arrFiltered = arrFilteredElem.concat(arrFilteredChildren);

        arrFiltered = arrFiltered.filter(
            (item, index) => arrFiltered.indexOf(item) === index
        );

        list.forEach((indicator) => {
            indicator.visible = "display: none";
            arrFiltered.forEach((filtered) => {
                if (props.type == state.ACTIVITIES) {
                    if (indicator.id == filtered.id)
                        indicator.visible = ""
                } else {
                    if (indicator.skill_id == filtered.skill_id)
                        indicator.visible = ""
                }
            });
        });
    },

    setColumnsTooltip: (_, column) => {
        const knowledge = document.getElementById(`knowledge${column.id}`)
        const removeColIcon = document.getElementById(`remove-column${column.id}`)
        const newColumnIcon = document.getElementById(`new-column${column.id}`)
        const confirmColNameIcon = document.getElementById(`confirm-column-name-icon-${column.id}`)
        const cancelColNameIcon = document.getElementById(`cancel-column-name-icon-${column.id}`)
        const columnDepth = document.getElementById(`depth${column.depth}`)

        if (removeColIcon)
            Gems.Tooltip(removeColIcon, "Apagar nível", "tooltip")

        if (knowledge)
            Gems.Tooltip(knowledge, "Coluna dos conhecimentos", "tooltip")

        if (confirmColNameIcon)
            Gems.Tooltip(confirmColNameIcon, "Confirmar", "tooltip")

        if (cancelColNameIcon)
            Gems.Tooltip(cancelColNameIcon, "Cancelar", "tooltip")

        if (newColumnIcon)
            Gems.Tooltip(newColumnIcon, "Criar nova coluna", "tooltip")

        if (columnDepth)
            Gems.Tooltip(columnDepth, `Abrir todos os elementos do nível ${column.depth} e anteriores`, "tooltip")
    },

    setElementsTooltips: (_, element) => {
        const newElem = document.getElementById(`new-elem${element.id}`)
        const checkbox = document.getElementById(`checkbox${element.id}`)
        const deleteElem = document.getElementById(`delete${element.id}`)
        const confirmElemNameIcon = document.getElementById(`confirm-elem-name${element.id}`)
        const cancelElemNameIcon = document.getElementById(`cancel-elem-name${element.id}`)

        if (newElem)
            Gems.Tooltip(newElem, "Criar um novo elemento filho", "tooltip")

        if (deleteElem)
            Gems.Tooltip(deleteElem, "Remover elemento e todos os seus filhos da árvore", "tooltip")

        if (checkbox)
            Gems.Tooltip(checkbox, "Ver filhos", "tooltip")

        if (confirmElemNameIcon)
            Gems.Tooltip(confirmElemNameIcon, "Confirmar", "tooltip")

        if (cancelElemNameIcon)
            Gems.Tooltip(cancelElemNameIcon, "Cancelar", "tooltip")

    },

    setSelectedMeta: (state, arr) => {
        state.selectedMeta = arr

        for (let column of state.tree) {
            if (column.id != arr?.id)
                column.selected = false
        }
    },

    resetTree(state) {
        Object.assign(state, defaultTree());
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
