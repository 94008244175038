<script setup>
import { onMounted, onUpdated } from "vue"
import { ref } from "@vue/reactivity"
import { KNOLIA } from "@/knolia"
import { Gems } from "@/gems"
import RankSkills from "@/components/RankSkills.vue"
import router from "@/router"
import ValidateSkills from "@/components/ValidateSkills.vue"
import RankSkillsModal from "@/components/RankSkillsModal.vue"
import { MESSAGES } from "../messages"

const validateSkills = ref(false)
const showChat = ref(false)
const showModal = ref(false)
const modalElement = ref([])
const refreshList = ref(false)

function ControlModal(args) {
  if(args.chat)
    showChat.value = true
  else 
    showChat.value = false

  modalElement.value = args.elem
  showModal.value = args.bool
}

async function SetValidation(elem) {
  const response = KNOLIA.CallBackEnd("post", "control-rating-abilities", { elem, type: 'set-validation' })

  if(response.error) {
    Gems.Telegram(MESSAGES.ERROR.DEFAULT, 'tele-not-allowed')
    return
  }
}

onMounted(() => {
  validateSkills.value = router.currentRoute.value.params.validateSkills
});


onUpdated(() => {
  validateSkills.value = router.currentRoute.value.params.validateSkills
});
</script>

<template>
  <div class="page-container">
    <RankSkills v-if="!validateSkills" @control-modal="ControlModal($event)" :refresh="refreshList"/>
    <ValidateSkills v-else @control-modal="ControlModal($event)" :refresh="refreshList"/>
    <RankSkillsModal v-if="showModal" class="modal-component" :element="modalElement" :show-chat="showChat"
      :validate-skills="validateSkills" @control-modal="ControlModal($event)" @set-validation="SetValidation($event)"
      @refresh-list="refreshList = $event" @change-tab="showChat = $event"
    />
  </div>
</template>

<style scoped></style>
