import { KNOLIA } from "../../knolia";
import { Gems } from "../../gems";
import router from "../../router";
import { MESSAGES } from "../../messages";

const state = {
  session: [],
};

const getters = {
  Session: (state) => state.session,
};

const actions = {
  async HasAccess({ dispatch }, credentials) {
    await KNOLIA.CallBackEnd("post", "login", {
      user: credentials["username"],
      pass: credentials["password"],
    })
      .then((response) => {
        if (typeof response.data != "string") {
          const item = { value: response.data["error"] == false ? response.data["error"] : false }
          const admin = { value: response.data["admin"] }

          localStorage.setItem("auth", JSON.stringify(item))
          localStorage.setItem("admin", JSON.stringify(admin))

          if (response.data["error"] == false)
            dispatch("GetPolicy")
          else
            Gems.Telegram(MESSAGES.ERROR[response.data.message], "tele-not-allowed")

        } else {
          Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed")
        }
      })
  },

  async RecoverPasswd(_, credentials) {
    return await KNOLIA.CallBackEnd("post", "reset-passwd", {
      user: credentials["username"],
      pass: credentials["password"],
      forgot: credentials["forgot"],
    });
  },

  async GetPolicy() {
    await KNOLIA.CallBackEnd("get", "policy-control").then((res) => {
      if (res.data.length > 1)
        router.push({ path: "/home" })
      else
        router.push({ path: "/privacy" })
    });
  },
};

const mutations = {
  storeSession: (state, session) => {
    state.session = session;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
