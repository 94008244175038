<!-- eslint-disable no-unused-vars -->
<script setup>
import { KNOLIA } from "@/knolia"
import { defineEmits, defineProps } from "vue"

const props = defineProps(["comment", "reply", "replied_comment"])
const emits = defineEmits(["control-reply", "delete-comment"])

</script>

<template>
    <section :class="{'section-comment': !replied_comment, 'section-replied-comment': replied_comment, 'comment-right': comment.sender_id == KNOLIA.GetLoggedUserId()}"
        :key="comment.comment_id" :id="`comment${comment.comment_id}`">
        <slot/>
        <header class="header-comment">
          <div class="div-user-header-left">
            <div class="div-user-photo">
              <div class="cover" :style="`background-image: url(${comment.photo_src}), url(${require('@/images/default.png')});`"></div>

            </div>
            <span class="div-user-name">{{ comment.sender_name }}</span>
          </div>
          <div>
              <span class="div-date">{{ comment.date }}</span>
              <i class="fas fa-times close-icon" v-if="reply" @click="emits('control-reply')"></i>
          </div>
        </header>
        <article class="text-area">
          <p class="text">{{ comment.comment }}</p>
          <div class="message-icons" v-if="!replied_comment && !reply">
            <div v-if="comment.sender_id == KNOLIA.GetLoggedUserId()">
              <i class="fa fa-trash" style="cursor: pointer;" @click="emits('delete-comment', comment.comment_id)"></i>
            </div>
            <div>
              <i class="fas fa-reply" style="cursor: pointer;" @click="emits('control-reply', comment)"></i>
            </div>
          </div>
        </article>
    </section>
</template>

<style scoped>

.section-comment, .section-replied-comment {
  padding: 5px;
  background-color: rgb(66 92 230 / 40%);
  border-radius: 5px;
  width: 85%;
}

.section-replied-comment {
  background-color: white;
  width: 99%;
  cursor: pointer;
}

.comment-right {
  align-self: end;
  background-color: rgb(42 212 233 / 40%);
}

.header-comment {
  box-shadow: 0 10px 10px -15px rgba(0, 0, 0, .7);
  padding: 5px 2px;
  display: flex;
  justify-content: space-between;
}

.close-icon {
  position: relative;
  top: -10px;
  right: -2px;
  margin-left: 10px;
  cursor: pointer;
}

.text-area {
  display: flex;
  justify-content: space-between;
  margin: 10px 5px 0 5px;
}

.div-user-header-left {
  display: flex;
  align-items: center;
}

.div-user-name {
  font-weight: bold;
}

.text {
  margin: 0;
  text-align: start;
  word-break: break-all;
}

.div-date {
  align-self: center;
}

.message-icons {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.cover {
  border-radius: 50%;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  width: 30px;
  height: 30px;
}

.div-user-photo {
  margin-right: 5px;
}

.div-user-photo i {
  vertical-align: bottom;
}
</style>
