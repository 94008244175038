<script setup>
import { ref } from 'vue';
import SearchUsers from '@/components/SearchUsers.vue'
import HistoryUsersList from '@/components/HistoryUsersList.vue'

let showList = ref(false)
</script>


<template>

    <div class="tab-container">
        <div class="tab" :class="{'tab-active': !showList}" @click="showList = !showList">ENCONTRAR PESSOAS</div>
        <div class="tab" :class="{'tab-active': showList}" @click="showList = !showList">LISTAS GUARDADAS</div>
    </div>

    <div class="page-container">
        <SearchUsers v-if="!showList" />
        <HistoryUsersList v-else-if="showList" />
    </div>
</template>

<style scoped>

.tab-container {
  display: flex;
  justify-content: space-between;
}

.tab {
  width: 50%;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  background-color: #060714bf;
  cursor: pointer;
  font-size: 100%;
  opacity: 0.4;
  color: black;
}

.tab-active {
  opacity: 1;
  background: none;
  border: none;
  color: black;
}
</style>
